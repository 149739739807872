export default {
    common: {
        title: 'DeFi Wallet',
        symbol: '$',
        currency: 'dollars',
        login: 'login',
        lang1: 'Just now',
        lang2: ' minutes ago',
        lang3: ' hours ago',
        lang4: 'Yesterday',
        lang5: 'My Assets',
        lang6: 'Yesterday\'s Earnings',
        lang7: 'View More',
        lang8: 'DeFi',
        lang9: 'Fund',
        lang10: 'Favorites',
        lang11: 'Highest 7-Day Annualized Return',
        lang12: 'Low Risk',
        lang13: 'Medium Low Risk',
        lang14: 'Medium High Risk',
        lang15: 'High Risk',
        lang16: ' 3 Years',
        lang17: '1 Year',
        lang18: 'Accumulated Earnings',
        lang19: 'Holding',
        lang20: 'Cancel',
        lang21: 'No data available at the moment',
        lang22:'confirm',
        lang23:'cancel',
        lang24:'No more',
        lang25: 'Language',
        lang26:'No card bound',
        lang27:'Order details',
        lang28:'Scan the QR code and add me as a friend',
        lang29:'Received successfully',
        lang30:'Copy success',
        lang31: 'Catatan'

    },
    shareCard: {
        lang1: 'petunjuk',
        lang2: 'konfirmasi',
        lang3: 'Menambahkan kartu pos akan menghapus semua kartu, apakah Anda yakin ingin menambahkannya?',
        lang4: 'Menambahkan gambar akan menghapus kartu foto, apakah Anda yakin menambahkannya?',
        lang5: 'Bersinar',
        lang6: 'Selesai',
    },
    tabbar: {
        lang1: 'Hari ini',
        lang2: 'Dana',
        lang3: 'saya'
    },
    login: {
        lang1: 'Buat akun baru',
        lang2: 'Sudah punya akun',
        lang3: 'Hubungi layanan pelanggan',
        lang4: 'Login tidak sah, harap otorisasi ulang'
    },
    home: {
        lang1: 'Diutamakan untuk pengguna baru',
        lang2: 'Jangan pernah kehilangan uang dan menarik uang kapan pun',
        lang3: 'Beli sejumlah uang dan coba',
        lang4: 'Pengingat penjualan aset langka',
        lang5: 'Tekan lama untuk mengikuti akun',
        lang6: 'Resmi WeChat',
        lang7: 'Jumlah pengguna investasi melebihi 400 juta, dan aset yang dikelola melebihi 2 triliun',
        lang8: 'Variasi yang sangat banyak',
        lang9: 'Perdagangkan dan beli puluhan ribu obligasi, dana, dan kontrak berjangka di seluruh dunia',
        lang10: 'keamanan',
        lang11: 'Tim pengendalian risiko yang dilokalisasi secara global untuk secara ketat mencegah risiko akun dan modal',
        lang12: 'Rincian pendapatan'
    },
    balance: {
        lang1: 'Riwayat penghasilan positif setiap hari, penarikan akun cepat',
        lang2: 'Lihat detailnya',
        lang3: 'Jumlah total',
        lang4: 'Jual',
        lang5: 'beli',
        lang6: 'papan diskusi',
        lang7: '4 fitur utama DeFi',
        lang8: 'Pendapatan stabil',
        lang9: 'Serbaguna',
        lang10: 'keluarkan kapan saja',
        lang11: 'keamanan',
        lang12: 'Detail transaksi',
        lang13: 'Rincian pendapatan',
        lang14: 'Jumlah transfer',
        lang15: 'Dapat ditransfer keluar',
        lang16: 'Silakan masukkan jumlahnya',
        lang17: 'Operasi selesai',
        lang18: 'Jumlah transfer',
        lang19: 'Telah diberitahu tentang peringatan risiko dan menyetujui perjanjian layanan',
        lang20: 'Silakan periksa perjanjian layanan',
        lang21: 'dapat ditransfer',
    },
    comment: {
        lang1: '"Forum Diskusi Yue Bao"',
        lang2: 'Detail produk',
        lang3: 'terbaru',
        lang4: 'postingan panas',
        lang5: 'Diposting',
        lang6: 'Ungkapkan pendapat',
        lang7: 'Publikasikan',
        lang8: 'Tambahkan pendapatmu',
        lang9: 'gambar',
        lang10: 'ekspresi',
        lang11: 'Isi tidak boleh kosong',
        lang12: 'Berhasil dipublikasikan',
    },
    fund:{
        a1:'Tentang kami',
        a2:'Pendapatan',
        a3:'Jumlah total dana yang dibeli',
        a4:'Penghasilan kemarin',
        a5:'Total pendapatan',
        a6:'Perlindungan utama',
        a7:'Lihat selengkapnya',
        a8: 'Alokasi yang seimbang dari berbagai industri, kinerja terus mengungguli pasar',
        a9:'Pilihan aktif',
        a10: 'Disetujui oleh lebih dari 310.000 pengguna',
        a11:'Pasar super selama delapan tahun berturut-turut',
        a12:'Optimasi kuantifikasi berbantuan AI',
        a13:'Beberapa tata letak yang presisi',
        a14:'Fokus pada gaya topi kecil dan menengah',
        a15: 'Menata ulang perusahaan-perusahaan yang membayar dividen tinggi',
        a16:'Peningkatan dalam lima tahun melebihi 100%',
        a17:'Pemilihan saham kuantitatif',
        a18:'Meningkat dan menurun dalam tiga tahun terakhir',
        a19:'Peningkatan dan penurunan dalam setahun terakhir',
        a20: 'risiko rendah',
        a21:'risiko sedang',
        a22: 'risiko menengah-tinggi',
        a23:'Risiko tinggi',
        a24:'Keuntungan kumulatif bagi pengguna',
        a25:'pada',
        a26:'Penukaran fleksibel',
        a27:'Rekomendasi ahli',
        a28:'Mengejar pendapatan tahunan',
        a29:'Tingkat kuat',
        a30:'Berkah data besar',
        a31:'siklus pendek',
        a32:'Diselesaikan pada hari yang sama',
        a33:'Sangat menguntungkan',
        a34:'BISNIS SEPERTI MILIK ANDA TRUSTWORLD FISKAL',
        a35:'Sejak tahun 2004, hampir 1.000.000 pengguna di seluruh dunia telah menghasilkan pendapatan $8 miliar melalui WORLD FISCAL dan merek mitranyaDana Anda dilindungi oleh lembaga keuangan terkemuka'
    },
    fundPurchase:{
        b1:"Tencent Teng'an",
        b2:'Jumlah pembelian',
        b3:'Aturan perdagangan',
        b4:'Jumlah dana yang dapat dibeli adalah:',
        b5:'Nilai beli',
        b6:'Silakan masukkan kata sandi pembayaran',
        b7:'Passwordnya 6 digit',
        b8: 'Saya mengetahui peringatan risiko dan menyetujui perjanjian layanan',
        b9:'Pembelian berhasil',
        b10:'Masukan salah',
        b11:'Silakan centang kotak untuk menyetujui',
        b12:'Pilih untuk membeli',
        b13:'Beli mulai dari 1 yuan',
        b14:"Keuntungan yang diharapkan:",
        b15:"Metode penyelesaian:",
        b16:"Hari Berakhir",
        b17: "Penyelesaian pada saat jatuh tempo",
        b18:"Durasi siklus:",
        b19:"Day",
        b20:"Deskripsi detailnya:",
        b21:"Belum ada penjelasan",
       


    },
    user:{
        c1:'Tentang kami',
        c2:'Tentang kami',
        c3:'Selamat datang di perjalanan kekayaan',
        c4:'Aset Saya',
        c5:'Uang percobaan',
        c6:'Aset Fleksibel',
        c7:'Aset yang bagus',
        c8:'Aset tingkat tinggi',
        c9:'Pengembalian jangka pendek',
        c10:'Keuntungan stabil',
        c11:'Mengejar keuntungan',
        c12:'Layanan alat',
        c13:'Catatan transaksi',
        c14:'Catatan saldo',
        c15:'Catatan Dana',
        c16:'Informasi lebih lanjut',
        c17:'Keamanan Akun',
        c18:'Layanan pelanggan saya',
        c19:'Tentang kami',
        c20:'Penarikan',
        c21:'Isi ulang',
    },
  
    accountChanges:{
        d1:'Tentang kami',
        d2:'Catatan perubahan akun'
    },
    funChanges:{
        e1:'Catatan transaksi dana',
        e2:'sedang berlangsung',
        e3:'Kedaluwarsa',
        e4:'Sisa waktu',
        e5:'Day',
        e6:'Detail pesanan',
        e7:'Statusnya:',
        e8:'sedang berlangsung',
        e9:'Berakhir',
        e10:'Penyelesaian ditangguhkan',
        e11:'Durasi siklus',
        e12:'Nama produk',
        e13:'Nomor pesanan',
        e14:'Jumlah pembelian',
        e15:'Pendapatan kumulatif',
        e16:'Metode penyelesaian',
        e17:'Deskripsi Produk',
        e18:'Waktu mulai',
        e19:'Waktu berakhir',
        e20:'Jumlah hari yang tersisa',
    },
    userInfo:{
        f1:'Informasi pribadi',
        f2:'Laki-laki',
        f3:'perempuan',
        f4:"Usia",
        f5:'Silakan pilih negara',
        f6:'Nama bank',
        f7:'Nomor kartu bank',
        f8: "Silakan atur password transaksi",
        f9:'Konfirmasi kata sandi transaksi',
        f10:'Silakan masuk',
        f11:'Silakan masuk lagi',
        f12:'Kirim',
        f13:''
    },
    fundDetails:{
        e1:'Tentang kami',
        e2:'risiko rendah',
        e3:'risiko sedang',
        e4:'risiko sedang hingga tinggi',
        e5:'Risiko tinggi',
        e6:'Pembelian minimum Yuan',
        e7:'Menetapkan peningkatan',
        e8:'Peningkatan dalam setahun terakhir',
        e9:'Peningkatan dalam tiga tahun terakhir',
        e10:'Beli sekarang',
        e11:'Pertunjukan sejarah',
        e12: 'Transfer 10.000 yuan, dan pendapatan harian dalam 7 hari terakhir adalah sebagai berikut',
        e13:'Pembelian'
    },
    bankCard:{
        a1:'Tambahkan kartu bank',
        a2:'Pilih negara',
        a3:'Pilih bank',a4:'Nomor kartu bank',
        a5:'Silakan masukkan nomor kartu bank Anda',
        a6:'Pemegang Kartu',
        a7: 'Silakan masukkan nama pemegang kartu',
        a8:'Lihat kartu bank yang terikat',
        a9: 'mengikat',
        a10:'Silakan masukkan kata sandi pembayaran',
        a11:'Pengikatan berhasil',
        a12:'Masukan salah',
        a13:'Kartuku',
        a14:'Kamu belum mengikat kartu keamanannya',
        a15:'Modifikasi kartu bank',
        a16:'Kartu Keamanan',
        a17:'Jadilah kartu yang aman',
        a18: 'Kartu yang digunakan untuk membeli produk keuangan untuk pertama kalinya akan menjadi kartu aman',
        a19:'Keluarkan kartu keamanan',
        a20: 'Kartu keamanan terikat pada rekening keuangan, dan dana dari bank hanya dapat ditarik dari kartu keamanan',
        a21:'Aman dan terjamin',
        a22: 'Bahkan jika ponsel Anda hilang, dana hanya dapat ditarik ke kartu keamanan Anda',
        a23:'Ganti kartu keamanan',
        a24: 'Setelah lolos review resmi, kartu keamanan dapat diganti',
        a25: 'Nama Cabang',
        a26: 'Nomor Cabang',
        a27: 'Nama samaran',
        a28: 'Pilih Jaringan',
        a29: 'Alamat',
        a30: 'Silakan Masukkan Alamat',


    },
    accountChanges:{
        a1: 'perubahan akun',
        a2: 'ketik',
        a3:'Pilih jenis',
        a4:'Nomor pesanan',
        a5:'jumlah',
        a6:'Saldo sebelum pembelian',
        a7:'Saldo setelah pembelian',
        a8:'waktu',
        a9:'Nomor pesanan',
        a10:'Penarikan',
        a11: 'Membekukan',
        a12:"Transfer ke Yu'E Bao",
        a13:'Beli Dana',
        a14:'pengurangan',
        a15:'Hadiah login harian',
        a16:'Isi ulang Alipay',
        a17:'Pengelolaan keuangan berakhir',
        a18:"Transfer keluar dari Yu'E Bao",
        a19:'Mencairkan',
        a20:'Penarikan gagal',
        a21:'Daftar gratis',
        a22:'Aktivitas',
        a23:'Pendapatan pengelolaan keuangan harian',
        a24:'Keuntungan produk siklis',
        a25: 'hadiah',
        a26:'Pendapatan Tertahan'

    },
    setPwd:{
        a1:'Setel kata sandi transaksi',
        a2:'Kata sandi transaksi',
        a3:'Silakan masukkan 6 digit password transaksi',
        a4:'Konfirmasi kata sandi transaksi',
        a5:'Silakan masukkan kembali password transaksi',
        a6:'Silakan masukkan 6 digit password transaksi',
        a7:'Simpan',
        a8:'Pengaturan berhasil',
        a9: 'Jika ada kesalahan pada inputan anda, silakan masukkan kembali',
    },
    Withdraw:{
        a2: 'Mundur',
        a3:'Jumlah penarikan',
        a4:'Kata sandi transaksi',
        a5:'Masukkan kata sandi transaksi',
        a6:'Lihat catatan penarikan',
        a7:'Mundur',
        a8:'Penarikan uang tunai',
        a9:'Jumlah uang tunai yang dapat ditarik'
    },
    WithdrawRecord:{
        e1:'Catatan transaksi dana',
        e2:'sedang berlangsung',
        e3:'Rekam',
        e4:'Penarikan gagal',
        e5:'Dalam peninjauan',
        e6:'Detail pesanan',
        e7:'Statusnya:',
        e8:'Dalam peninjauan',
        e9:'Berakhir',
        e10:'Penyelesaian ditangguhkan',
        e11:'Alamat penarikan',
        e12:'Nama bank',
        e13:'Pemegang Kartu',
        e14:'mata uang',
        e15:'Waktu pembuatan',
        e16:'Penarikan berhasil',
        e17:'Waktu pembaruan',
        e18:'Jumlah'
    },
    aboutas:{
        a1:"Tentang Kami",
        a2:"WorldFirst FOF didirikan di London, Inggris pada tahun 2004 dan sejak itu telah memberikan layanan berkualitas tinggi kepada pengguna di seluruh dunia. Kami bergantung pada jaringan ekosistem keuangan lintas batas, menawarkan kepada pelanggan kami beragam solusi produk dan jaminan keamanan dana.",
        a3: "Lisensi Keuangan",
        a4:"Kami memiliki kualifikasi untuk ekspansi global melalui kepemilikan atau kerja sama dengan agen merek lokal di banyak negara dan wilayah termasuk AS, Inggris, Kanada, Belanda, Jepang, Tiongkok Daratan, Hong Kong, Malaysia, Singapura, dan Australia, memberikan jaminan kuat untuk keamanan dana pelanggan dan mendukung ekspansi global pelanggan!",
        a5: "Kepatuhan Keamanan Dana",
        a6: "Persyaratan Kepatuhan",
        a7: "Kami telah mendirikan tim kepatuhan standar nasional, sepenuhnya mematuhi standar internasional anti-pencucian uang dan anti-pembiayaan terorisme, sepenuhnya mematuhi hukum dan peraturan terkait, dan mengambil langkah-langkah yang diperlukan untuk mencegah risiko keuangan.",
        a8: "Perlindungan Data Privasi",
        a9: "Informasi dan privasi dilindungi oleh kebijakan privasi yang ketat, dikelola dengan cermat, dengan risiko penyalahgunaan minimal, dengan enkripsi teknis untuk setiap transfer data, dan akses yang dibatasi dengan kontrol fisik yang ketat.",
        a10: "Jaminan Transaksi Dana",
        a11: "Semua operasi harus dilakukan sesuai dengan instruksi pelanggan. Tanpa otorisasi, pihak ketiga tidak dapat memanipulasi dana pelanggan.",
        a12: "Investasi Global EMC",
        a13: "WorldFirst FOF memiliki kantor di 49 kota di 18 negara di seluruh dunia, menyediakan layanan profesional yang lebih disesuaikan.",
        a14: "Daftar sekarang, buka rekening, dan hubungkan peluang bisnis global."
    },
    recharge: {
        r1: 'Funds Security Guarantee',
        r2: 'Up to a million fund compensation',
        r3: 'Real-time crediting',
        r4: 'No handling fees',
        r5: 'Withdraw anytime',
        r6: 'Recharge Amount',
        r7: 'Recharge Now',
        r8: 'Why Choose Us',
        r9: 'Diverse Investments',
        r10: 'Higher Returns',
        r11: 'Abundant Mentors',
        r12: 'Zero Loss',
        r13: 'Recharge',
        r14: 'Payment Method',
        r15: 'Enter Amount',
        r16: 'Please select',
        r17: 'Recharge Records',
        r18: 'Payment Method',
        r19: 'Please select a country',
        r20: 'Payment Link',
        r21: 'Please copy and open in browser',
        r22: 'Paid',
        r23: 'Copy',
        r24: 'Copy successful!',
        r25: 'Processing',
        r26: 'Top-up success',
        r27: 'Top-up failed'
    },
    invite: {
        i1: 'Claim reward',
        i2: 'Please enter invitation code',
        i3: 'Please enter correct invitation code'
    },
    home1: {
        h1: "MAKE MONEY LIKE A PRO",
        h2: "Quick Withdrawals",
        h3: "Professional operation",
        h4: "Quick profit",
        h5: "1. Same day income, 0 handling fee",
        h6: "2. 20 years of stable growth and zero losses",
        h7: "3. 24-hour professional monitoring of market conditions",
        h8: "Built for Growth and Ambition",
        h9: "Currency Exchange, Team Management, Accounting And",
        h10: "More – All In One Place.",
        h11: "Currency Exchange Risk Management",
        h12: "Multi-User Authorization",
        h13: "Ecosystem Integration",
        h14: "About Worldfirst",
        h15: "Years Of History",
        h16: "Customers's Trust",
        h17: "Global Footprint",
        h18: "Transaction Value",
        h19: "Hear What Our Customers Say",
        h20: "Currency Exchange, Team Management, Accounting And",
        h21: "More – All In One Place.",
        h22: "Peter Keen",
        h23: "Electrical Director",
        h24: "Using WORLD FISCAL gives me peace of mind，Because I know the responsibilities of the communities we work with are respected and safeguarded，Keeping my profits and assets safe",
        h25: "Raffi Schier",
        h26: "malaysian bank manager",
        h27: "I purchased banking products in Malaysia and found it very time consuming and challenging,but in comparison, opening an account through WORLD FISCAL was easy and efficient",
        h28: "Monica Milcarz",
        h29: "Director of Yingfa Group",
        h30: "Yu'e Bao is the most stable product I have ever purchased.It helped me get through the toughest times and makes me a better person now.",
    },
    webLogin: {
        "w1": "Masuk",
        "w2": "Daftar",
        "w3": "Akun",
        "w4": "Kata Sandi",
        "w5": "Negara",
        "w6": "Kota",
        "w7": "Silakan masukkan akun",
        "w8": "Silakan masukkan kata sandi",
        "w9": "Berhasil masuk",
        "w10": "Pendaftaran berhasil",
        "w11": 'Dimulai dengan huruf,panjang 6~18',
        "w12": 'Panjang 6~18',
        "w13": 'Login with LINE',
        "w14": 'Login with facebook',
    },
    vip: {
        v1: 'Output',
        v2: 'Wallet',
        v3: 'award',
        v4: 'Untuk lebih banyak reward, silakan hubungi layanan pelanggan',
        v5: 'Insentif ini tidak akan berlangsung selamanya. Bergabunglah sekarang untuk mendapatkan hadiah yang besar, tetapi pastikan untuk mematuhi syarat dan ketentuan. Silakan kunjungi situs web kami untuk informasi lebih lanjut.'
    },
    defi: {
        "d1": "Ada dividen dengan transaksi",
        "d2": "Produk dan fitur",
        "d3": "Pengembalian stabil",
        "d4": "Platform biasanya menawarkan suku bunga yang lebih tinggi daripada produk bank tradisional",
        "d5": "Menghilangkan risiko",
        "d6": "Platform adalah [penyimpanan mandiri] dan tidak akan pernah mempercayakan aset pengguna kepada operator terpusat",
        "d7": "Akses bebas kapan saja",
        "d8": "Akses bebas memungkinkan dana lebih fleksibel dan nyaman",
        "d9": "Protokol stablecoin",
        "d10": "Gunakan stablecoin untuk meminimalkan volatilitas cryptocurrency. Koin-koin ini sering dipegang dengan mata uang stabil seperti dolar AS, menjadikannya sebagai media pertukaran dan penyimpan nilai yang dapat diterima"
    }
    

}