export default {
    common: {
        title: 'DeFi Wallet',
        symbol: '¥',
        currency: '元',
        login: 'लॉगिन',
        lang1: 'हाल ही में',
        lang2: 'मिनट पहले',
        lang3: 'घंटे पहले',
        lang4: 'कल',
        lang5: 'मेरी संपत्ति',
        lang6: 'कल की कमाई',
        lang7: 'और देखें',
        lang8: 'DeFi',
        lang9: 'फंड',
        lang10: 'वैकल्पिक',
        lang11: 'दैनिक आय',
        lang12: '0 जोखिम',
        lang13: 'कम जोखिम',
        lang14: 'मध्यम जोखिम',
        lang15: 'उच्च जोखिम',
        lang16: 'पिछले तीन वर्षों में वृद्धि और कमी',
        lang17: 'पिछले वर्ष में वृद्धि और कमी',
        lang18: 'संचयी आय',
        lang19: 'यह उत्पाद खरीदें',
        lang20: 'रद्द करें',
        lang21: 'अभी तक कोई डेटा नहीं',
        lang22:'पुष्टि करें',
        lang23:'रद्द करें',
        lang24:'अब और नहीं',
        lang25: 'भाषा',
        lang26:'कोई कार्ड बाध्य नहीं',
        lang27:'बिलिंग विवरण',
        lang28:'क्यूआर कोड स्कैन करें और मुझे मित्र के रूप में जोड़ें',
        lang29:'सफलतापूर्वक प्राप्त हुआ',
        lang30:'सफलतापूर्वक कॉपी करें',
        lang31: 'टिप्पणियाँ'
        
    },
    shareCard: {
        lang1: 'सूचना',
        lang2: 'पुष्टि',
        lang3: 'एक स्नैप कार्ड जोड़ने से सभी हट जाएगा, क्या आप जोड़ना चाहते हैं?',
        lang4: 'तस्वीर जोड़ने से स्नैप कार्ड हटा दिया जाएगा, क्या आप जोड़ना चाहते हैं?',
        lang5: 'स्नैप कार्ड',
        lang6: 'पूरा'
    },
    tabbar: {
        lang1: 'आज',
        lang2: 'फंड',
        lang3: 'मेरा'
    },
    login: {
        lang1: 'नया खाता बनाएं',
        lang2: 'पहले से खाता है',
        lang3: 'संपर्क सेवा',
        lang4: 'अनधिकृत लॉगइन, कृपया पुनः प्रमाणीकरण करें'
    },
    home: {
        lang1: 'नए उपयोगकर्ता के लिए पसंदीदा',
        lang2: 'कभी भी कमाने के लिए  निकासी',
        lang3: 'एक परीक्षण खरीदें',
        lang4: 'दुर्लभ एसेट बिक्री अधिसूचना',
        lang5: 'खाता को फ़ॉलो करने के लिए लंबित',
        lang6: 'व्यावसायिक स्तर पर',
        lang7: 'निवेशकों की संख्या 4 बिलियन से अधिक है, प्रबंधन धन 20000 बिलियन से अधिक है',
        lang8: 'विविध प्रकार का उत्पाद',
        lang9: 'व्यापार और खरीद में विश्व भर में हजारों बोन्ड फंड आइटम',
        lang10: 'सुरक्षा गारंटी',
        lang11: 'विश्वव्यापी स्थानीय कार्यान्वयन नियंत्रण टीम, खाता और निधि जोखिमों के खिलाफ कड़ी रोकथाम',
        lang12: 'लाभ विवरण'
    },
    balance: {
        lang1: 'इतिहास दिन प्रतिदिन स्थिर आय, त्वरित निकासी खाता',
        lang2: 'विवरण देखें',
        lang3: 'कुल रकम',
        lang4: 'बेचें',
        lang5: 'खरीदें',
        lang6: 'चर्चा क्षेत्र',
        lang7: '"बैलेंस" 4 मुख्य विशेषताएँ',
        lang8: 'आय स्थिर',
        lang9: 'उपयोग का विस्तार',
        lang10: 'कब भी निकासी करें',
        lang11: 'सुरक्षा गारंटी',
        lang12: 'व्यापार विवरण',
        lang13: 'लाभ विवरण',
        lang14: 'निकासी राशि',
        lang15: 'निकासी कर सकते हैं',
        lang16: 'कृपया राशि दर्ज करें',
        lang17: 'कार्रवाई पूरी',
        lang18: 'राशि दर्ज करें',
        lang19: 'सेवा समझौते को स्वीकार करने के बारे में जागरूक हैं और सहमत हैं',
        lang20: 'कृपया सेवा समझौते को चेक करें',
        lang21: 'सक्रिय हो सकता है'
    },
    comment: {
        lang1: '"बैलेंस बातचीत क्षेत्र"',
        lang2: 'उत्पाद विवरण',
        lang3: 'नवीनतम',
        lang4: 'हॉट पोस्ट',
        lang5: 'ने पोस्ट किया',
        lang6: 'विचार व्यक्त करें',
        lang7: 'पोस्ट करें',
        lang8: 'अपने विचार को पोस्ट करें',
        lang9: 'तस्वीर',
        lang10: 'इमोजी',
        lang11: 'सामग्री खाली नहीं हो सकती',
        lang12: 'सफलतापूर्वक पोस्ट किया गया',
    },
    fund:{
        a1:'हमारे बारे में',
        a2:'आय',
        a3:'कुल फंड खरीदी गई',
        a4:'कल की आय',
        a5:'कुल आय',
        a6:'मूलधन सुरक्षा',
        a7:'और देखें',
        a8:'समग्र विभागों के संतुलित वितरण, परिणाम सतत बड़े बाजार को पीछे छोड़ देता है',
        a9:'सक्रिय पसंदीदा',
        a10:'31 लाख से अधिक उपयोगकर्ताओं द्वारा स्वीकृत',
        a11:'8 वर्षों से बड़े बाजार को पीछे छोड़ रहा है',
        a12:'AI सहायक मात्रात्मक चयन',
        a13:'कई बार सटीक रणनीति',
        a14:'मध्यम और छोटे बाजार के शैली पर ध्यान दें',
        a15:'उच्च वितरण उत्पादों पर ध्यान दें',
        a16:'5 साल में बड़ी वृद्धि',
        a17:'मात्रात्मक चयन शेयर',
        a18:'पिछले 3 साल के रेंज',
        a19:'पिछले 1 साल के रेंज',
        a20:'कम जोखिम',
        a21:'मध्यम जोखिम',
        a22:'मध्यम उच्च जोखिम',
        a23:'उच्च जोखिम',
        a24:'उपयोगकर्ताओं के लाभ की संख्या',
        a25:'समाप्त होने की तिथि',
        a26:'संवेदनशील आवेदन',
        a27:'विशेषज्ञ सिफारिश',
        a28:'वार्षिक आय की पीछा करना',
        a29:'स्थिर स्तर',
        a30:'बड़े डेटा से सहायता',
        a31:'छोटे अवधि',
        a32:'तत्काल निपटान',
        a33:'उच्च लाभ',
        a34:'आपकी Trustworld Fiscal जैसी कंपनियों के लिए',
        a35:'2004 से, वर्ल्ड फिस्कल और इसके सहयोगी ब्रांड के माध्यम से लगभग 1,000,000 उपयोगकर्ता ने 80 अरब डॉलर की कमाई बनाई है, आपकी धनराशि अग्रणी वित्तीय संस्थानों द्वारा संरक्षित है'
    },
    fundPurchase:{
        b1:'Tencent Teng An',
        b2:'खरीद राशि',
        b3:'व्यापार नियम',
        b4:'आप खरीदने के लिए फंड की अधिकतम राशि है:',
        b5:'खरीदने की दर',
        b6:'कृपया भुगतान पासवर्ड दर्ज करें',
        b7:'पासवर्ड 6 अंकों का होता है',
        b8:'खतरा सूचना को समझ लिया है और सेवा समझौते से सहमत है',
        b9:'खरीदी सफल',
        b10:'गलत इनपुट',
        b11:'कृपया सहमति की जाँच करें',
        b12:'खरीद चुनें',
        b13:'1 रुपया से शुरू',
        b14:"प्रत्याशित लाभ:",
        b15:"लेन-देन तंत्र:",
        b16:"दैनिक निपटान",
        b17:"समाप्ति निपटान",
        b18:"अवधि की लंबाई:",
        b19:"दिन",
        b20:"विस्तृत विवरण:",
        b21:"वर्तमान में कोई विवरण उपलब्ध नहीं है",
    },
    user:{
        c1:'हमारे बारे में',
        c2:'हमारे बारे में',
        c3:'धन की यात्रा की शुरुआत के लिए आपका स्वागत है',
        c4:'मेरी संपत्ति',
        c5:'परीक्षण धन',
        c6:'लचीला पोषण',
        c7:'स्थिर पोषण',
        c8:'उच्च स्तरीय पोषण',
        c9:'छोटी अवधि वापसी',
        c10:'स्थिर लाभ',
        c11:'लाभ की प्राप्ति',
        c12:'टूल सेवाएं',
        c13:'लेखा परिवर्तन रिकॉर्ड',
        c14:'शेष रिकॉर्ड',
        c15:'फंड रिकॉर्ड',
        c16:'अधिक जानकारी',
        c17:'खाता सुरक्षा',
        c18:'मेरी सहायता',
        c19:'हमारे बारे में',
        c20:'निकासी',
        c21:'शुल्क',
    },
       
    accountChanges:{
        d1:'हमारे बारे में',
        d2:'लेखा परिवर्तन रिकॉर्ड'
    },
    funChanges:{
        e1:'फंड व्यापार रिकॉर्ड',
        e2:'के बीच',
        e3:'समाप्त हो गया',
        e4:'शेष समय',
        e5:'दिन',
        e6:'आदेश विवरण',
        e7:'स्थिति:',
        e8:'के बीच',
        e9:'समाप्त हो गया',
        e10:'निर्वाह स्थगित',
        e11:'अवधि की लंबाई',
        e12:'उत्पाद का नाम',
        e13:'ऑर्डर नंबर',
        e14:'खरीदी की मात्रा', 
        e15:'कुल आय',
        e16:'लेन-देन तंत्र',
        e17:'उत्पाद विवरण',
        e18:'प्रारंभ समय',
        e19:'समाप्ति समय',
        e20:'शेष दिन',
    
    },
    userInfo:{
        f1:'व्यक्तिगत जानकारी',
        f2:'पुरुष',
        f3:'महिला',
        f4:"आयु",
        f5:'कृपया देश चुनें',
        f6:'बैंक का नाम',
        f7:'बैंक कार्ड नंबर',
        f8:"कृपया लेन-देन पासवर्ड सेट करें",
        f9:'लेन-देन पासवर्ड की पुष्टि करें',
        f10:'कृपया दर्ज करें',
        f11:'कृपया फिर से दर्ज करें',
        f12:'प्रस्तुत',
        f13:''
    },    
    fundDetails:{
        e1:'हमारे बारे में',
        e2:'कम जोखिम',
        e3:'मध्यम जोखिम',
        e4:'मध्यम-उच्च जोखिम',
        e5:'उच्च जोखिम',
        e6:'रुपये से शुरुआत',
        e7:'स्थापना यायावर वृद्धि',
        e8:'पिछले 1 साल में वृद्धि',
        e9:'पिछले 3 साल में वृद्धि',
        e10:'तुरंत खरीदें',
        e11:'इतिहास दर्शाएं',
        e12:'₹1 लाख जमा करने पर, पिछले 7 दिनों के दैनिक लाभ इस प्रकार हैं',
        e13:'खरीदें'
    },
    bankCard:{
        a1:'बैंक कार्ड जोड़ें',
        a2:'देश चुनें',
        a3:'बैंक चुनें',
        a4:'बैंक कार्ड नंबर',
        a5:'कृपया बैंक कार्ड नंबर दर्ज करें',
        a6:'कार्ड धारक का नाम',
        a7:'कृपया कार्ड धारक का नाम दर्ज करें',
        a8:'बांधे गए बैंक कार्ड देखें',
        a9:'बाँधें',
        a10:'कृपया भुगतान पासवर्ड दर्ज करें',
        a11:'बाँधना सफल',
        a12:'गलत इनपुट',
        a13:'मेरे कार्ड',
        a14:'आपने कोई सुरक्षित कार्ड बाधित नहीं किया है',
        a15:'बैंक कार्ड संशोधित करें',
        a16:'सुरक्षित कार्ड',
        a17:'सुरक्षित कार्ड बनें',
        a18:'वित्तीय उत्पाद की पहली खरीदी का कार्ड सुरक्षित कार्ड बन जाएगा',
        a19:'सुरक्षित कार्ड निकालें',
        a20:'सुरक्षित कार्ड और वित्तीय खाता बांधना, बैंक से आए धन को केवल सुरक्षित कार्ड से निकाला जा सकता है',
        a21:'सुरक्षित सुनिश्चित',
        a22:'मोबाइल खो जाए तो भी, पैसे केवल आपके सुरक्षित कार्ड में निकाले जा सकते हैं',
        a23:'सुरक्षित कार्ड बदलें',
        a24:'आधिकारिक मंजूरी के बाद, सुरक्षित कार्ड बदला जा सकता है',
        a25:'शाखा का नाम',
        a26:'शाखा का नंबर',
        a27: 'नाम',
        a28: 'नेटवर्क चुनें',
        a29: 'पता',
        a30: 'कृपया पता दर्ज करें',
    
    },
    accountChanges:{
        a1:'लेखा परिवर्तन',
        a2:'प्रकार',
        a3:'प्रकार चुनें',
        a4:'ऑर्डर नंबर',
        a5:'राशि',
        a6:'खरीद पूर्व शेष राशि',
        a7:'खरीद के बाद शेष राशि',
        a8:'समय',
        a9:'ऑर्डर नंबर',
        a10:'निकासी सफल',
        a11:'बंद',
        a12:'बैलेंस ट्रांसफर',
        a13:'फंड खरीदें',
        a14:'कटौती',
        a15:'प्रतिदिन लॉगिन इनाम',
        a16:'भुगतान रिचार्ज',
        a17:'वित्तीय उत्पाद समाप्त हो गया',
        a18:'बैलेंस ट्रांसफर',
        a19:'अनफ्रीज',
        a20:'निकासी असफल',
        a21:'पंजीकरण उपहार',
        a22:'क्रियाकलाप',
        a23:'वित्तीय उत्पाद प्रतिदिन लाभ',
        a24:'अवधि उत्पाद लाभ',
        a25:'उपहार',
        a26:'होल्डिंग इनकम'
    },
    setPwd:{
        a1:'व्यापारिक पासवर्ड सेट करें',
        a2:'व्यापारिक पासवर्ड',
        a3:'कृपया 6 अंकों का व्यापारिक पासवर्ड दर्ज करें',
        a4:'व्यापारिक पासवर्ड की पुष्टि करें',
        a5:'कृपया फिर से व्यापारिक पासवर्ड दर्ज करें',
        a6:'6 अंकों का व्यापारिक पासवर्ड दर्ज करें',
        a7:'सहेजें',
        a8:'सेटिंग सफल',
        a9:'गलत इनपुट कृपया फिर से दर्ज करें',
    },
    Withdraw:{
        a2:'निकास',
        a3:'निकास राशि',
        a4:'व्यापारिक पासवर्ड',
        a5:'व्यापारिक पासवर्ड दर्ज करें',
        a6:'निकास रिकॉर्ड देखें',
        a7:'निकास',
        a8:'निकास हो रहा है',
        a9:'निकास की योग्य राशि'
    },
    WithdrawRecord:{
        e1:'फंड व्यापार रिकॉर्ड',
        e2:'चल रहा है',
        e3:'रिकॉर्ड',
        e4:'निकास विफल',
        e5:'समीक्षा के लिए प्रस्तुत',
        e6:'ऑर्डर विवरण',
        e7:'स्थिति:',
        e8:'समीक्षा के लिए प्रस्तुत',
        e9:'समाप्त हो गया',
        e10:'स्थगित सेटलमेंट',
        e11:'निकास पता',
        e12:'बैंक का नाम',
        e13:'कार्ड धारक',
        e14:'मुद्रा',
        e15:'निर्माण का समय',
        e16:'निकास सफल',
        e17:'अपडेट समय',
        e18:'राशि'
    },
    aboutas:{
        a1:"हमारे बारे में",
        a2:"WorldFirst FOF का गठन 2004 में लंदन, यूके में हुआ था और तब से यह विश्वभर में उपयोगकर्ताओं को उच्च गुणवत्ता वाली सेवाएं प्रदान कर रहा है। हम एक सीमांतर वित्तीय पारिस्थितिकी नेटवर्क पर निर्भर करते हैं, जो हमारे ग्राहकों को समृद्ध उत्पाद समाधान और निधि सुरक्षा गारंटी प्रदान करता है।",
        a3: "वित्तीय लाइसेंस",
        a4:"हमारे पास संप्रदायक या कई देशों और क्षेत्रों में स्थानीय ब्रांड एजेंसियों के साथ सहयोग के माध्यम से वैश्विक विस्तार के लिए योग्यता है, जैसे कि संयुक्त राज्य अमेरिका, संयुक्त राज्य, कनाडा, नीदरलैंड्स, जापान, मुख्य चीन, हांगकांग, मलेशिया, सिंगापुर, और ऑस्ट्रेलिया, ग्राहक निधि सुरक्षा के लिए मजबूत गारंटी प्रदान करता है और ग्राहक वैश्विक विस्तार का समर्थन करता है!",
        a5: "निधि सुरक्षा अनुपालन",
        a6: "अनुपालन आवश्यकताएं",
        a7: "हमने राष्ट्रीय मानक अनुपालन टीम की स्थापना की है, अंतरराष्ट्रीय धोधापान और आतंकवादी वित्त प्रमाणों को पूरी तरह से पालन किया, संबंधित कानूनों और विनियमों की मांगों का पूरा अनुपालन किया, और वित्तीय जोखिमों को रोकने के लिए आवश्यक कदम उठाए।",
        a8: "डेटा गोपनीयता संरक्षण",
        a9: "जानकारी और गोपनीयता को कठिनता से गोपनीयता नीतियों द्वारा संरक्षित किया जाता है, संबंधितता से प्रबंधित किया जाता है, उपयोग के खतरे को कम से कम किया जाता है, प्रत्येक डेटा स्थानांतरण के लिए तकनीकी एन्क्रिप्शन होती है, और पहुंच को सख्त भौतिक नियंत्रणों के साथ प्रतिबंधित किया गया है।",
        a10: "लेन-देन सुरक्षा गारंटी",
        a11: "सभी क्रियाएं ग्राहक निर्देशों के अनुसार की जानी चाहिए। अनधिकृति के बिना, तृतीय पक्ष ग्राहक निधियों को संचालित नहीं कर सकता।",
        a12: "वैश्विक EMC निवेश",
        a13: "WorldFirst FOF के पास पूरी दुनिया में 18 देशों के 49 शहरों में कार्यालय हैं, अधिक अनुकूलित पेशेवर सेवाएं प्रदान करते हुए।",
        a14: "अभी पंजीकरण करें, खाता खोलें, और वैश्विक व्यापारिक अवसरों को लिंक करें।"
    },
    recharge: {
        r1: 'वित्तीय सुरक्षा सुरक्षा में',
        r2: 'लाखों रुपये की तक मिलेगा',
        r3: 'तत्काल खाते में जमा',
        r4: 'कोई प्रक्रिया शुल्क',
        r5: 'कभी भी निकास',
        r6: 'पुनरार्जित राशि',
        r7: 'तत्काल भुगतान करें',
        r8: 'हमारा चयन क्यों करें',
        r9: 'विविध निवेश',
        r10: 'अधिक लाभ',
        r11: 'विशाल मार्गदर्शक',
        r12: '0 हानि',
        r13: 'पुनरार्जित',
        r14: 'भुगतान तरीका',
        r15: 'कृपया राशि दर्ज करें',
        r16: 'कृपया भुगतान तरीका चुनें',
        r17: 'पुनरार्जित रिकॉर्ड',
        r18: 'भुगतान तरीका',
        r19: 'कृपया देश चुनें',
        r20: 'भुगतान लिंक',
        r21: 'कृपया इसे ब्राउज़र में कॉपी करें और खोलें',
        r22: 'भुगतान किया गया',
        r23: 'कॉपी',
        r24: 'कॉपी सफल!',
        r25: 'प्रोसेसिंग',
        r26: 'सफलतापूर्वक पुनरार्जित',
        r27: 'पुनरार्जित विफल',
    },
    invite: {
        i1: 'पुरस्कार प्राप्त करें',
        i2: 'कृपया आमंत्रण कोड दर्ज करें',
        i3: 'कृपया सही आमंत्रण कोड दर्ज करें'
    },
    home1: {
        "h1": "एक पेशेवर के रूप में पैसे कमाएं",
        "h2": "त्वरित निकास",
        "h3": "पेशेवर प्रबंधन",
        "h4": "त्वरित लाभ",
        "h5": "1. दिन भर में आय, कोई प्रक्रिया शुल्क नहीं",
        "h6": "2. 20 वर्षों के स्थिर वृद्धि, कोई नुकसान",
        "h7": "3. 24 घंटे में पेशेवर बाजार का पर्यवेक्षण",
        "h8": "विकास और उम्मीद के लिए निर्मित",
        "h9": "मुद्रा विनिमय, टीम प्रबंधन, लेखा और",
        "h10": "अधिक - सभी के लिए समाधान।",
        "h11": "मुद्रा विनिमय जोखिम प्रबंधन",
        "h12": "बहु उपयोगकर्ता अधिवाहन",
        "h13": "पारिस्थितिकी एकीकरण",
        "h14": "वर्ल्ड फर्स्ट के बारे में",
        "h15": "इतिहासिक वर्ष",
        "h16": "ग्राहक विश्वास",
        "h17": "वैश्विक पादप्रमुख",
        "h18": "व्यापार मूल्य",
        "h19": "हमारे ग्राहकों ने क्या कहा है यह सुनें",
        "h20": "मुद्रा विनिमय, टीम प्रबंधन, लेखा और",
        "h21": "अधिक - सभी के लिए समाधान।",
        "h22": "पीटर कीन",
        "h23": "इलेक्ट्रिकल डायरेक्टर",
        "h24": "WORLD FISCAL का उपयोग करने से मेरे दिल को शांति मिलती है, क्योंकि मुझे पता है कि हमारे साझा समुदाय का प्रतिबद्धता सम्मान और सुरक्षा में बना है, जो मेरी लाभ और संपत्ति को सुरक्षित रखता है",
        "h25": "राफी शीयर",
        "h26": "मलेशिया बैंक प्रबंधक",
        "h27": "मैंने मलेशिया में बैंक के उत्पाद खरीदे, जो बहुत समय लेते और चुनौतीपूर्ण हैं, लेकिन इसके बजाय, WORLD FISCAL के माध्यम से खाता खोलना बहुत आसान और कुशल है",
        "h28": "मोनिका मिल्कार्ज",
        "h29": "इंग्लैंड ग्रुप के डायरेक्टर",
        "h30": "बैलेंस बोत मैंने खरीदा सबसे स्थिर उत्पाद है। यह मेरे सबसे कठिन समय में मेरी मदद की, अब यह मुझे एक बेहतर व्यक्ति बना देता है।"
    },
    webLogin: {
        w1: 'लॉगिन',
        w2: 'पंजीकरण',
        w3: 'खाता',
        w4: 'पासवर्ड',
        w5: 'देश',
        w6: 'शहर',
        w7: 'कृपया खाता दर्ज करें',
        w8: 'कृपया पासवर्ड दर्ज करें',
        w9: 'लॉगिन सफल',
        w10: 'पंजीकरण सफल',
        w11: 'पत्रिका शुरू होने पर अक्षरिक आधारित है',
        w12: '6 से 18 अक्षरों के बीच लंबाई',
        "w13": 'Login with LINE',
        "w14": 'Login with facebook',
    },
    vip: {
        v1: 'Output',
        v2: 'Wallet',
        v3: 'award',
        v4: 'अधिक पुरस्कार के लिए कृपया ग्राहक सेवा से संपर्क करें',
        v5: 'यह प्रोत्साहन हमेशा तक नहीं चलेगा। हमारे साथ शामिल होकर भारी पुरस्कार प्राप्त करें, लेकिन यह सुनिश्चित करें कि नियम और शर्तों का पालन करें। कृपया अधिक जानकारी के लिए हमारी वेबसाइट पर जाएं।'
    },
    defi: {
        "d1": "लेनदेन के साथ लाभांश होते हैं",
        "d2": "उत्पाद और विशेषताएं",
        "d3": "स्थिर रिटर्न",
        "d4": "प्लेटफ़ॉर्म आमतौर पर पारंपरिक बैंक उत्पादों की तुलना में उच्च ब्याज दरें प्रदान करता है",
        "d5": "जोखिम समाप्त करें",
        "d6": "प्लेटफ़ॉर्म [स्वयं-संरक्षक] है और कभी भी उपयोगकर्ता की संपत्ति को केंद्रीयकृत ऑपरेटर को नहीं सौंपेगा",
        "d7": "कभी भी स्वतंत्र पहुँच",
        "d8": "स्वतंत्र पहुँच से धन अधिक लचीला और सुविधाजनक होता है",
        "d9": "स्थिर मुद्रा प्रोटोकॉल",
        "d10": "क्रिप्टोकरेंसी की अस्थिरता को कम करने के लिए स्थिर मुद्राओं का उपयोग करें। ये सिक्के अक्सर अमेरिकी डॉलर जैसी स्थिर मुद्राओं से जुड़े होते हैं, जो उन्हें एक स्वीकार्य विनिमय माध्यम और मूल्य का भंडार बनाते हैं"
    }
    
    
}