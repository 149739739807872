import Vue from 'vue'
import store from '@/store/index.js'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
//中文
import zh from './zh.js'
//英语
import en from './en.js'
// 日文
import ja from './ja.js'
//马来
import ms from './ma.js'
//印尼
import id from './ind.js'
//越南
import vi from './vi.js'
//印度
import hi from './hi.js'

const messages = {
	en,
    ja,
	zh,
	ms,
	id,
	vi,
	hi
}
// 语言匹配优先级: 缓存 > 浏览器语言 > 默认
let locale = localStorage.getItem('UNI_LOCALE')
;(() => {
  // 浏览器语言
  if (!locale) {
    const browerLanguage = navigator.language.toLowerCase()
		const langKeyList = Object.keys(messages)
    const language = langKeyList.find(locale => browerLanguage.includes(locale))
    language && (locale = language)
  }
  //  默认
  if (!locale) {
    locale = 'en'
  }
})();

const i18n = new VueI18n({
	locale,
	messages
})
export function setLocale(data) {
	localStorage.setItem('UNI_LOCALE', data)
	i18n.locale = data
	store.commit('setLocale', data)
}
export default i18n