<template>
    <div>
        <transition name="fade">
            <div class="invite-box" v-show="isShowInvite">
                <div class="title">{{ $t("invite.i1") }}</div>
                <div class="content-box">
                    <div class="text">{{ $t("invite.i2") }}</div>
                    <div class="invitation-code-input">
                        <input
                            v-for="(item, index) in 5"
                            :key="index"
                            v-model="inputCodes[index]"
                            maxlength="1"
                            @input="handleInput(index)"
                            ref="inputs"
                            class="digit-input"
                            type="text"
                            inputmode="numeric"
                            style="appearance: none; width: 32px;height: 32px;"
                        />
                   </div>
                   <div class="btn" @click="submitCode">{{ $t("common.lang22") }}</div>
                </div>
                <van-icon @click="isShowInvite = false" class="close" size="30" name="cross" color="#fff"/>
            </div>
        </transition>
        <transition name="fade">
            <div class="tips-box" v-show="isShowTips">
                <van-icon name="certificate" size="60" color="#256feb"/>
                <div class="text">+ {{ text }}</div>
                <div class="btn" @click="isShowTips = false">{{ $t("common.lang22") }}</div>
            </div>
        </transition>
        <img @click="isShowInvite = true;" v-show="isShowInviteIcon" class="invite-img" src="../assets/icon/bonus.png" alt="">
        <!-- <img ref="button" v-show="isShowInviteIcon" class="invite-img" src="../assets/icon/bonus.png" alt=""
            @touchstart="startDrag"
            @touchmove="drag"
            @touchend="endDrag"
            :style="{ transform: `translate(${position.x}px, ${position.y}px)` }"> -->
    </div>
  </template>
  
<script>
import { getInviteCount,getGiftMoney } from '../api'
import { mapActions } from 'vuex';
export default {
    data() {
      return {
        inputCodes: Array(5).fill(''),
        isDragging: false,
        startPosition: { x: 0, y: 0 },
        position: { x: window.innerWidth-64, y: (window.innerHeight/2)-45 },
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
        buttonWidth: 32,
        buttonHeight: 32,
        isShowInvite: false,
        isShowInviteIcon: false,
        isShowTips: false,
        text: null,
        isMoved: false
      };
    },
    mounted() {
      this.getInviteCount()
    },
    methods: {
        ...mapActions(['fetchUserInfo']),
        async getInviteCount() {
            let res = await getInviteCount()
            if (res.code === 0 && res.data.count > 0) {
                this.isShowInviteIcon = true
            } else {
                this.isShowInviteIcon = false
            }
        },
        handleInput(index) {
            if(index > 0 && !this.inputCodes[index]) {
                this.$nextTick(() => {
                    const lastInput = this.$refs.inputs[index-1];
                    if (lastInput && typeof lastInput.focus === 'function') {
                        setTimeout(() => {
                            lastInput.focus();
                            lastInput.selectionStart = lastInput.value.length;
                            lastInput.selectionEnd = lastInput.value.length;
                        }, 0);
                    }
                });
            }
            if (index < 4 && this.inputCodes[index] !== '') {
                this.$nextTick(() => {
                    const nextInput = this.$refs.inputs[index+1];
                    if (nextInput && typeof nextInput.focus === 'function') {
                        nextInput.focus();
                    }
                });
            }
        },

        async submitCode() {
            let invitationCode = this.inputCodes.join('');
            if(invitationCode.length < 5) {
                this.$toast(this.$t("invite.i3"));
            } else {
                let res = await getGiftMoney({
                    code: invitationCode
                })
                if (res.code === 0) {
                    this.fetchUserInfo()
                    if(res.data.count == 0) this.isShowInviteIcon = false
                    this.isShowInvite = false
                    this.isShowTips = true
                    this.text = res.data.money
                } else {
                    this.$toast(res.msg)
                    this.inputCodes = Array(5).fill('')
                }
            }
            
        },
        startDrag(event) {
            event.preventDefault();
            this.isDragging = true;
            this.startPosition = {
                x: event.touches[0].clientX - this.position.x,
                y: event.touches[0].clientY - this.position.y
            };
        },
        drag(event) {
            this.isMoved = true
            if (this.isDragging) {
                let x = event.touches[0].clientX - this.startPosition.x;
                let y = event.touches[0].clientY - this.startPosition.y;
                let buttonRect = this.$refs.button.getBoundingClientRect();
                
                if (x >= 0 && x + buttonRect.width <= this.screenWidth) {
                this.position.x = x;
                }
                if (y >= 0 && y + buttonRect.height <= this.screenHeight) {
                this.position.y = y;
                }
            }
        },
        endDrag() {
            this.isDragging = false;
            if(!this.isMoved) this.isShowInvite = true;
            this.isMoved = false;
        }
    }
}
</script>
  
<style scoped lang="scss">
.tips-box {
    position: fixed;
    top: 52%;
    transform: translate(-50%,-50%);
    left: 50%;
    width: 280px;
    height: 200px;
    padding-top: 15px;
    background-color: #fff;
    border: 1px solid #E6EFFF;
    border-radius: 10px;
    z-index: 999;
    .text {
        margin-top: 20px;
        color: #333;
        font-size: 24px;
        font-weight: 600;
    }
    .btn {
        width: 220px;
        height: 44px;
        margin: 32px auto 0;
        line-height: 44px;
        text-align: center;
        color: #fff;
        border-radius: 30px;
        background: linear-gradient(to bottom, #7CBFFF, #1650F8); 
    }
}
.invite-box {
    position: fixed;
    top: 46%;
    transform: translate(-50%,-50%);
    left: 50%;
    width: 320px;
    height: 285px;
    background: url('../assets/images/invite-bgc.png') no-repeat;
    background-size: 100% 100%;
    text-align: left;
    z-index: 999;
    .title {
        margin-top: 56px;
        margin-left: 25px;
        font-size: 17px;
        font-weight: 600;
        color: #fff;    
    }
    .content-box {
        margin-top: 50px;
        padding: 0 25px;
        color: #000;
        box-sizing: border-box;
        .invitation-code-input {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
        }

        .digit-input {
            width: 32px;
            height: 32px;
            border-radius: 10px;
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            border: 1px solid #E9E9E9;
            background-color: #F7F7F7;
        }
        .btn {
            width: 280px;
            height: 44px;
            margin-top: 26px;
            line-height: 44px;
            text-align: center;
            color: #fff;
            border-radius: 30px;
            background: linear-gradient(to bottom, #7CBFFF, #1650F8); 
        }
    }
    .close {
        position: absolute;
        bottom: -46px;
        left: 50%;
        transform: translateX(-50%);
        padding: 4px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 50%;
    }

}
.invite-img {
    position: fixed;
    top: 8px;
    right: 48px;
    width: 27px;
    height: 27px;
    cursor: grab;
    transition: transform 0.1s ease;
    z-index: 99;
}
</style>
  