// api/index.js
import axios from 'axios';
import baseURL from '../apiConfig.js';
import router from '../router'
import store from '@/store/index.js';
// 创建一个 Axios 实例
const api = axios.create({
  baseURL:baseURL,
  timeout: 20000, // 请求超时时间
  withCredentials: true
});


// 在请求拦截器中设置 token
//测试 token 
api.interceptors.request.use(
  config => {
    let token = localStorage.getItem("token");
    let lang = store.state.locale || 'ja';
    config.headers['Lang'] = lang;  
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
// 设置 Axios 响应拦截器
api.interceptors.response.use(
  response => {
    if (response.data.code === 403) {
      if (router.currentRoute.path !== '/login') {
        router.push('/login').catch(err => { console.log(err) });
        localStorage.removeItem('token'); 
      }
    }
    return response.data;
  },
  error => {
    console.log(error)
    return Promise.reject(error);
  }
);
const put = (url, data) => api.put(url, data);
const get = (url, params) => api.get(url, { params });
const post = (url, data) => api.post(url, data);

export const financial = () => get('api/financial/index2'); //基金首页
export const financialMore = () => get('api/financial/index'); //基金详情
export const financialList = (data) => get('api/financial/index3',data); //基金列表
export const summery = () => get('api/financial/summery');//基金收益 
export const financialUser = () => get('api/financial/user');//查询理财记录 
export const reportDay = () => get('api/financial/reportDay');//每日收益 
export const incomeLog = () => get('api/financial/incomeLog');//查询理财收益记录 
export const financialBuy = (data) => post('api/financial/buy',data);//购买理财 
export const changeLog = (data) => get('api/wallet/changeLog',data);//账变 
export const payPass = (data) => post('api/user/payPass',data);//设置支付密码 
export const financialU = (data) => get('api/financial/user',data);//用户理财记录  
export const bankCountry = () => get('api/wallet/bankCountry');//查询国家 
export const banks = (data) => get('api/wallet/banks',data);//查询银行卡 
export const bindBank = (data) => post('api/wallet/bindBank',data);//绑定银行卡 
export const userBanks = () => get('api/wallet/userBanks');//查询用户已绑定的卡 
export const transactionType = () => get('api/wallet/transactionType');// 账变类型 
export const getDict = (data) => get('api/getDict',data);// 字典 
export const withdraw = (data) => post('api/wallet/withdraw',data);// 提现
export const getwithdraw = (data) => get('api/wallet/withdraw',data);// 提现查询 
export const returnRateLogs = (data) => get('api/financial/returnRateLogs',data);// 查询产品历史收益率 
export const getBankcard = (data) => post('api/wallet/getBankcard',data);// 查询银行卡号 
export const getrate = (data) => post('/api/sys/bank/rate',data);// 查询银行卡号 
export const like = (data) => put('api/financial/like',data);//点赞
export const addVisit = (data) => put('api/financial/addVisit',data);//访问量 
export const count = (data) => get('api/money_code/count',data);//查询赠送码 
export const obtain = (data) => post('api/money_code/obtain',data);// 提交赠送码

export const register = (data) => post('/api/wechat/create',data);
export const bind = (data) => post('/api/wechat/bind',data);
export const login = (data) => post('/api/wechat/login',data);

export const webLogin = (data) => post('/api/user/login',data);
export const webRegister = (data) => post('/api/user/register',data);
export const lineLoginApi = (data) => post('/api/user/lineLogin2',data);

// 余额+ start
export const getBalancePlusData = () => get('/api/balance_plus/data'); // 余额+ 余额

export const getBalanceRecord = (data) => get('/api/balance_plus/record',data); //交易\收益明细

export const transferBalance = (data) => post('/api/balance_plus/transfer',data); //转入转出

export const getCommentRecord = (data) => get('/api/comment/record',data); //评论记录

export const publishComment = (data) => post('/api/comment/publish',data); //发表评论

export const getReportDay = (data) => get('/api/financial/reportDay',data); 
// end
export const getUserInfo = () => get('/api/user/info'); // 余额+ 余额
export const upload = (data) => post('/api/sys/file/upload',data); //上传

export const getWechatCode = (data) => get('/api/sys/banner',data); //微信二维码

export const getBonusList = (data) => post('/api/activity/list',data); //活动列表
export const receiveBonus = (data) => post('/api/activity/give',data); //领取红包

export const getPayList = (data) => get('/api/deposit/pay_list',data); //查询充值方式
export const createRecharge = (data) => post('/api/deposit/create',data); //创建充值订单
export const getRecord = (data) => get('/api/deposit/record',data); //查询充值记录

export const getInviteCount = () => get('/api/money_code/count'); //获取邀请次
export const getGiftMoney = (data) => post('/api/money_code/obtain',data); //获取赠送金额

export const getVips = () => get('/api/user/vips');

export const getAvailableBalance = () => get('/api/wallet/availableBalance'); // 获取可提现余额

export const getDefiInfo = (data) => get('/api/defi/userInfo',data); // 获取defi钱包信息

export const getDefiRecord = (data) => get('/api/defi/logs',data); // 获取defi钱包交易明细

export const transferDefiBalance = (data) => post('/api/defi/transfer',data); // defi钱包转入转出
