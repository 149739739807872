export default {
    common: {
        title: 'DeFi Wallet',
        symbol: '$',
        currency: 'dollars',
        login: 'login',
        lang1: 'Tadi',
        lang2: 'minit lalu',
        lang3: 'jam yang lalu',
        lang4: 'semalam',
        lang5: 'Aset saya',
        lang6: 'Pendapatan semalam',
        lang7: 'Lihat lagi',
        lang8: 'DeFi',
        lang9: 'Dana',
        lang10: 'Pilihan',
        lang11: 'Pendapatan harian',
        lang12: '0 risiko',
        lang13: 'risiko rendah',
        lang14: 'risiko sederhana',
        lang15: 'berisiko tinggi',
        lang16: 'Meningkat dan menurun dalam tempoh tiga tahun yang lalu',
        lang17: 'pendapatan',
        lang18: 'Pendapatan kumulatif',
        lang19: 'Beli produk ini',
        lang20: 'Batal',
        lang21: 'Tiada data lagi',
        lang22:'sahkan',
        lang23:'Batal',
        lang24:'Tiada lagi',
        lang25: 'Bahasa',
        lang26:'Tiada kad terikat',
        lang27:'Butiran pesanan',
        lang28:'Imbas kod QR dan tambah saya sebagai rakan',
        lang29:'Berjaya diterima',
        lang30:'Copy success',
        lang31: 'Nota'


       
    },
    shareCard: {
        lang1: 'petunjuk',
        lang2: 'sahkan',
        lang3: 'Menambah poskad akan mengalih keluar semua kad, adakah anda pasti mahu menambahkannya?',
        lang4: 'Menambah gambar akan mengalih keluar kad foto, adakah anda pasti menambahkannya?',
        lang5: 'Bersinar',
        lang6: 'Lengkap',
    },
    tabbar: {
        lang1: 'Hari ini',
        lang2: 'Dana',
        lang3: 'saya'
    },
    login: {
        lang1: 'Buat akaun baharu',
        lang2: 'Sudah mempunyai akaun',
        lang3: 'Hubungi perkhidmatan pelanggan',
        lang4: 'Log masuk tanpa kebenaran, sila beri kebenaran semula'
    },
    home: {
        lang1: 'Diutamakan untuk pengguna baharu',
        lang2: 'Jangan sekali-kali kehilangan wang dan keluarkan wang pada bila-bila masa',
        lang3: 'Beli sejumlah dan cuba',
        lang4: 'Peringatan penjualan aset yang terhad',
        lang5: 'Tekan lama untuk mengikuti akaun',
        lang6: 'WeChat rasmi',
        lang7: 'Bilangan pengguna pelaburan melebihi 400 juta, dan aset di bawah pengurusan melebihi 2 trilion',
        lang8: 'Kepelbagaian besar-besaran',
        lang9: 'Berdagang dan beli berpuluh-puluh ribu bon, dana dan niaga hadapan di seluruh dunia',
        lang10: 'keselamatan',
        lang11: 'Pasukan kawalan risiko setempat secara global untuk mencegah risiko akaun dan modal dengan ketat',
        lang12: 'Butiran pendapatan'
    },
    balance: {
        lang1: 'Pendapatan positif sejarah setiap hari, pengeluaran akaun cepat',
        lang2: 'Lihat butiran',
        lang3: 'Jumlah amaun',
        lang4: 'Jual',
        lang5: 'beli',
        lang6: 'papan perbincangan',
        lang7: '4 ciri utama DeFi',
        lang8: 'Pendapatan stabil',
        lang9: 'Versatile',
        lang10: 'bawa keluar pada bila-bila masa',
        lang11: 'keselamatan',
        lang12: 'Butiran urus niaga',
        lang13: 'Butiran pendapatan',
        lang14: 'Jumlah pemindahan',
        lang15: 'Boleh dipindahkan keluar',
        lang16: 'Sila masukkan jumlah',
        lang17: 'Operasi selesai',
        lang18: 'Jumlah pemindahan',
        lang19: 'Telah dimaklumkan tentang amaran risiko dan bersetuju dengan perjanjian perkhidmatan',
        lang20: 'Sila semak perjanjian perkhidmatan',
        lang21: 'boleh dipindahkan',
    },
    comment: {
        lang1: '"Forum Perbincangan Yue Bao"',
        lang2: 'Butiran produk',
        lang3: 'terkini',
        lang4: 'siaran panas',
        lang5: 'Disiarkan',
        lang6: 'Menyatakan pendapat',
        lang7: 'Terbitkan',
        lang8: 'Tambahkan pendapat anda',
        lang9: 'gambar',
        lang10: 'ungkapan',
        lang11: 'Kandungan tidak boleh kosong',
        lang12: 'Berjaya diterbitkan',
    },
    fund:{
        a1: 'Tentang kami',
        a2: 'Pendapatan',
        a3:'Jumlah bilangan dana yang dibeli',
        a4: 'Pendapatan semalam',
        a5:'Jumlah pendapatan',
        a6:'Perlindungan utama',
        a7:'Lihat lagi',
        a8: 'Peruntukan seimbang bagi pelbagai industri, prestasi terus mengatasi prestasi pasaran',
        a9:'Pemilihan aktif',
        a10: 'Diluluskan oleh lebih 310,000 pengguna',
        a11: 'Pasaran super selama lapan tahun berturut-turut',
        a12:'Pengoptimuman kuantifikasi berbantukan AI',
        a13: 'Berbilang susun atur tepat',
        a14:'Fokus pada gaya topi kecil dan sederhana',
        a15: 'Sediakan perusahaan yang membayar dividen tinggi',
        a16: 'Peningkatan dalam tempoh lima tahun melebihi 100%',
        a17: 'Pemilihan stok kuantitatif',
        a18: 'Meningkat dan menurun dalam tempoh tiga tahun yang lalu',
        a19: ' tahun lepas',
        a20: 'risiko rendah',
        a21: 'risiko sederhana',
        a22:'risiko sederhana tinggi',
        a23:'Berisiko tinggi',
        a24:'Keuntungan terkumpul untuk pengguna',
        a25: 'sehingga',
        a26:'Penebusan fleksibel',
        a27:'Syor pakar',
        a28: 'Mengejar pendapatan tahunan',
        a29:'Tahap teguh',
        a30:'Berkat data besar',
        a31: 'kitaran pendek',
        a32:'Selesai pada hari yang sama',
        a33:'Sangat menguntungkan',
        a34:'PERNIAGAAN SEPERTI FISKAL DUNIA AMANAH ANDA',
        a35:'Sejak 2004, hampir 1,000,000 pengguna di seluruh dunia telah menjana pendapatan $8 bilion melalui WORLD FISCAL dan jenama rakan kongsinyaDana anda dilindungi oleh institusi kewangan terkemuka'
    },
    fundPurchase:{
        b1:"Tencent Teng'an'",
        b2:'Jumlah beli',
        b3:'Peraturan dagangan',
        b4:'Jumlah yang anda boleh beli dana ialah:',
        b5:'Kadar beli',
        b6:'Sila masukkan kata laluan pembayaran',
        b7:'Kata laluan ialah 6 digit',
        b8: 'Saya sedar tentang amaran risiko dan bersetuju dengan perjanjian perkhidmatan',
        b9: 'Pembelian berjaya',
        b10: 'Input salah',
        b11: 'Sila tandakan kotak untuk bersetuju',
        b12: 'Pilih untuk membeli',
        b13:'Beli bermula dari 1 yuan',
        b14:"Keuntungan yang dijangkakan:",
        b15:"Kaedah penyelesaian:",
        b16:"Tamat Hari",
        b17: "Penyelesaian semasa matang",
        b18:"Tempoh kitaran:",
        b19:"Day",
        b20:"Perihalan terperinci:",
        b21: "Belum ada penjelasan",
       


    },
    user:{
        c1: 'Tentang kami',
        c2: 'Tentang kami',
        c3: 'Selamat datang ke perjalanan kekayaan',
        c4:'Aset Saya',
        c5:'Wang percubaan',
        c6:'Aset Fleksibel',
        c7:'Aset bunyi',
        c8:'Aset peringkat tinggi',
        c9:'Pulangan jangka pendek',
        c10: 'keuntungan stabil',
        c11: 'Kejar untung',
        c12:'Perkhidmatan alat',
        c13:'Rekod Transaksi',
        c14:'Rekod baki',
        c15:'Rekod Dana',
        c16:'Maklumat lanjut',
        c17:'Keselamatan Akaun',
        c18:'Khidmat pelanggan saya',
        c19:'Tentang kami',
        c20:'Pengeluaran',
        c21: 'Caj semula',
    },
  
    accountChanges:{
        d1: 'Tentang kami',
        d2:'Rekod perubahan akaun'
    },
    funChanges:{
        e1:'Rekod transaksi dana',
        e2:'sedang berjalan',
        e3: 'Tamat tempoh',
        e4: 'Masa yang tinggal',
        e5:'day',
        e6:'Butiran pesanan',
        e7:'Status:',
        e8: 'sedang berjalan',
        e9:'Tamat',
        e10: 'Penyelesaian digantung',
        e11:'Tempoh kitaran',
        e12:'Nama produk',
        e13:'Nombor pesanan',
        e14:'Kuantiti pembelian',
        e15: 'Pendapatan terkumpul',
        e16:'Kaedah penyelesaian',
        e17:'Perihalan Produk',
        e18: 'Masa mula',
        e19: 'Masa tamat',
        e20:'Bilangan hari yang tinggal',
    },
    userInfo:{
        f1: 'Maklumat peribadi',
        f2: 'Lelaki',
        f3: 'perempuan',
        f4: "Umur",
        f5:'Sila pilih negara',
        f6:'Nama bank',
        f7:'Nombor kad bank',
        f8: "Sila tetapkan kata laluan transaksi",
        f9:'Sahkan kata laluan transaksi',
        f10: 'Sila masukkan',
        f11:'Sila masukkan semula',
        f12:'Serah',
        f13:''
    },
    fundDetails:{
        e1:'Tentang kami',
        e2: 'risiko rendah',
        e3: 'risiko sederhana',
        e4: 'berisiko sederhana hingga tinggi',
        e5: 'Berisiko tinggi',
        e6:'Pembelian minimum Yuan',
        e7: 'Tetapkan peningkatan',
        e8: 'Peningkatan pada tahun lalu',
        e9: 'Peningkatan dalam tiga tahun yang lalu',
        e10:'Beli sekarang',
        e11: 'Prestasi sejarah',
        e12: 'Pindahkan 10,000 yuan, dan pendapatan harian dalam 7 hari yang lalu adalah seperti berikut',
        e13:'Beli'
    },
    bankCard:{
        a1:'Tambah kad bank',
        a2:'Pilih negara',
        a3:'Pilih bank',a4:'Nombor kad bank',
        a5:'Sila masukkan nombor kad bank anda',
        a6:'Pemegang Kad',
        a7: 'Sila masukkan nama pemegang kad',
        a8:'Lihat kad bank terikat',
        a9: 'mengikat',
        a10:'Sila masukkan kata laluan pembayaran',
        a11: 'Berjaya mengikat',
        a12: 'Input salah',
        a13:'Kad saya',
        a14:'Anda belum mengikat kad keselamatan lagi',
        a15:'Ubah suai kad bank',
        a16:'Kad Keselamatan',
        a17: 'Menjadi kad selamat',
        a18: 'Kad yang digunakan untuk membeli produk kewangan buat kali pertama akan menjadi kad selamat',
        a19:'Alih keluar kad keselamatan',
        a20: 'Kad keselamatan terikat pada akaun kewangan, dan dana daripada bank hanya boleh dikeluarkan daripada kad keselamatan',
        a21:'Selamat dan terjamin',
        a22: 'Walaupun telefon anda hilang, dana hanya boleh dikeluarkan ke kad keselamatan anda',
        a23:'Ganti kad keselamatan',
        a24: 'Selepas lulus semakan rasmi, kad keselamatan boleh diganti',
        a25: 'Nama Cawangan',
        a26: 'Nombor Cawangan',
        a27: 'Nama samaran',
        a28: 'Pilih Rangkaian',
        a29: 'Alamat',
        a30: 'Sila Masukkan Alamat',

    },
    accountChanges:{
        a1: 'perubahan akaun',
        a2: 'jenis',
        a3:'Pilih jenis',
        a4:'Nombor pesanan',
        a5:'jumlah',
        a6:'Baki sebelum pembelian',
        a7:'Baki selepas pembelian',
        a8: 'masa',
        a9:'Nombor pesanan',
        a10: 'Pengeluaran',
        a11: 'Bekukan',
        a12: "Berpindah ke Yu'E Bao",
        a13:'Beli Dana',
        a14: 'potongan',
        a15:'Ganjaran log masuk harian',
        a16:'Alipay cas semula',
        a17: 'Pengurusan kewangan tamat tempoh',
        a18: "Pemindahan keluar dari Yu'E Bao",
        a19:'Nyahbeku',
        a20: 'Pengeluaran gagal',
        a21:'Daftar percuma',
        a22:'Aktiviti',
        a23: 'Pendapatan pengurusan kewangan harian',
        a24:'Keuntungan produk kitaran',
        a25:'hadiah',
        a26:'Pendapatan Pemegang'
    },
    setPwd:{
        a1:'Tetapkan kata laluan transaksi',
        a2:'Kata laluan urus niaga',
        a3:'Sila masukkan kata laluan transaksi 6 digit',
        a4:'Sahkan kata laluan transaksi',
        a5:'Sila masukkan kata laluan transaksi sekali lagi',
        a6:'Sila masukkan kata laluan transaksi 6 digit',
        a7:'Simpan',
        a8:'Set berjaya',
        a9: 'Jika terdapat ralat dalam input anda, sila masukkan semula',
    },
    Withdraw:{
        a2: 'Tarik diri',
        a3:'Jumlah pengeluaran',
        a4:'Kata laluan urus niaga',
        a5: 'Masukkan kata laluan transaksi',
        a6:'Lihat rekod pengeluaran',
        a7:'Tarik diri',
        a8: 'Mengeluarkan wang tunai',
        a9:'Jumlah tunai yang boleh dikeluarkan'
    },
    WithdrawRecord:{
        e1:'Rekod transaksi dana',
        e2:'sedang berjalan',
        e3:'Rekod',
        e4: 'Pengeluaran gagal',
        e5:'Dalam semakan',
        e6:'Butiran pesanan',
        e7:'Status:',
        e8:'Dalam semakan',
        e9:'Tamat',
        e10: 'Penyelesaian digantung',
        e11:'Alamat pengeluaran',
        e12:'Nama bank',
        e13:'Pemegang Kad',
        e14:'mata wang',
        e15: 'Masa penciptaan',
        e16: 'Pengeluaran berjaya',
        e17: 'Masa kemas kini',
        e18:'Jumlah'
    },
    aboutas:{
        a1:"Tentang Kami",
        a2:"WorldFirst FOF ditubuhkan pada tahun 2004 di London, UK. Sejak itu, kami telah menyediakan perkhidmatan berkualiti tinggi kepada pengguna di seluruh dunia dan bergantung pada rangkaian kerjasama ekologi keuangan lintas batas, memberikan pelanggan kami dengan pelbagai produk dan jaminan keselamatan dana yang kaya.",
        a3: "Lesen Perkhidmatan Kewangan",
        a4:"Kami mempunyai kualifikasi ekspansi global melalui kepemilikan atau kerjasama dengan badan jenama tempatan di banyak negara dan kawasan termasuk Amerika Syarikat, United Kingdom, Kanada, Belanda, Jepun, China Daratan, Hong Kong, Malaysia, Singapura, dan Australia, memberikan jaminan kukuh untuk keselamatan dana pelanggan dan mendukung usaha global pelanggan!",
        a5: "Pematuhan Keselamatan Dana",
        a6: "Keperluan Pematuhan Peraturan",
        a7: "Kami telah menubuhkan pasukan pematuhan piawai nasional, mematuhi sepenuhnya standard pematuhan antarabangsa anti-pencucian wang dan anti-pembiayaan pengganas, mematuhi sepenuhnya keperluan undang-undang dan peraturan berkaitan, serta mengambil langkah-langkah yang diperlukan untuk mencegah risiko kewangan.",
        a8: "Perlindungan Data Privasi",
        a9: "Maklumat dan privasi dilindungi oleh dasar privasi yang ketat, diurus dengan teliti, dan risiko penyalahgunaan dikekalkan pada tahap minimum, dengan penyulitan teknikal bagi setiap pemindahan data, dan akses terhad dengan kawalan fizikal yang ketat.",
        a10: "Jaminan Transaksi Dana",
        a11: "Semua operasi mesti dilaksanakan mengikut arahan pelanggan. Tanpa kebenaran, pihak ketiga tidak boleh memanipulasi dana pelanggan.",
        a12: "Pembelaburan Global EMC",
        a13: "WorldFirst FOF mempunyai pejabat di 49 bandar di 18 negara di seluruh dunia, menyediakan perkhidmatan profesional yang lebih terkustomasi.",
        a14: "Daftar sekarang, buka akaun, dan jalinkan peluang perniagaan global."
    },
    recharge: {
        r1: 'Jaminan Keselamatan Dana',
        r2: 'Pampasan dana sehingga sejuta',
        r3: 'Sejurus masuk akaun',
        r4: 'Tiada caj urusan',
        r5: 'Bila-bila masatunai',
        r6: 'Jumlah Topup',
        r7: 'Topup Sekarang',
        r8: 'Kenapa Pilih Kami',
        r9: 'Pelaburan Pelbagai',
        r10: 'Pulangan yang Lebih Tinggi',
        r11: 'Pelbagai Mentor',
        r12: 'Sifar Kerugian',
        r13: 'Isi semula',
        r14: 'Cara Pembayaran',
        r15: 'Masukkan Jumlah',
        r16: 'Sila pilih',
        r17: 'Rekod Isi Semula',
        r18: 'Cara Pembayaran',
        r19: 'Sila pilih negara',
        r20: 'Pautan Pembayaran',
        r21: 'Sila salin dan buka dalam pelayar',
        r22: 'Dibayar',
        r23: 'Salin',
        r24: 'Berjaya disalin!',
        r25: 'Sedang diproses',
        r26: 'Berjaya topup',
        r27: 'Gagal topup'
    },
    invite: {
        i1: 'Tuntut hadiah',
        i2: 'Sila masukkan kod jemputan',
        i3: 'Sila masukkan kod jemputan yang betul'
    },
    home1: {
        "h1": "BUAT WANG SEPERTI SEORANG PROFESIONAL",
        "h2": "Pengeluaran Cepat",
        "h3": "Operasi Profesional",
        "h4": "Keuntungan Cepat",
        "h5": "1. Pendapatan pada hari yang sama, tiada yuran pemprosesan",
        "h6": "2. 20 tahun pertumbuhan stabil dan tiada kerugian",
        "h7": "3. Pemantauan pasaran profesional 24 jam",
        "h8": "Dibangunkan untuk Pertumbuhan dan Ambisi",
        "h9": "Pertukaran Mata Wang, Pengurusan Pasukan, Perakaunan dan",
        "h10": "Lebih - Semuanya Dalam Satu Tempat.",
        "h11": "Pengurusan Risiko Pertukaran Mata Wang",
        "h12": "Kebenaran Pelbagai Pengguna",
        "h13": "Pengintegrasian Ekosistem",
        "h14": "Tentang World First",
        "h15": "Tahun Sejarah",
        "h16": "Keyakinan Pelanggan",
        "h17": "Jejak Global",
        "h18": "Nilai Transaksi",
        "h19": "Dengar Apa yang Pelanggan Kami Katakan",
        "h20": "Pertukaran Mata Wang, Pengurusan Pasukan, Perakaunan dan",
        "h21": "Lebih - Semuanya Dalam Satu Tempat.",
        "h22": "Peter Keen",
        "h23": "Pengarah Elektrikal",
        "h24": "Menggunakan WORLD FISCAL memberi saya ketenangan fikiran, kerana saya tahu tanggungjawab komuniti yang kami bekerja dengan dihormati dan dilindungi, menjaga keuntungan dan aset saya selamat",
        "h25": "Raffi Schier",
        "h26": "Pengurus Bank Malaysia",
        "h27": "Saya membeli produk perbankan di Malaysia dan mendapati ia sangat memakan masa dan mencabar, tetapi berbanding dengan itu, membuka akaun melalui WORLD FISCAL adalah mudah dan cekap",
        "h28": "Monica Milcarz",
        "h29": "Pengarah Kumpulan Yingfa",
        "h30": "Yu'e Bao adalah produk yang paling stabil yang pernah saya beli. Ia membantu saya melalui masa-masa yang paling sukar dan membuat saya menjadi orang yang lebih baik sekarang."
    },
    webLogin: {
        "w1": "Log Masuk",
        "w2": "Daftar",
        "w3": "Akaun",
        "w4": "Kata Laluan",
        "w5": "Negara",
        "w6": "Bandar",
        "w7": "Sila masukkan akaun",
        "w8": "Sila masukkan kata laluan",
        "w9": "Berjaya log masuk",
        "w10": "Pendaftaran berjaya",
        "w11": 'Bermula dengan huruf,panjang 6~18',
        "w12": 'Panjang 6~18',
        "w13": 'Login with LINE',
        "w14": 'Login with facebook',
    },
    vip: {
        v1: 'Output',
        v2: 'Wallet',
        v3: 'award',
        v4: 'Untuk lebih banyak ganjaran, sila hubungi perkhidmatan pelanggan',
        v5: 'Insentif ini tidak akan berterusan selamanya. Sertai kami sekarang untuk mendapatkan ganjaran yang besar, tetapi perlu mematuhi terma dan syarat. Sila lawati laman web kami untuk maklumat lanjut.'
    },
    defi: {
        "d1": "Terdapat dividen dengan transaksi",
        "d2": "Produk dan ciri",
        "d3": "Pulangan stabil",
        "d4": "Platform biasanya menawarkan kadar faedah yang lebih tinggi daripada produk bank tradisional",
        "d5": "Menghapuskan risiko",
        "d6": "Platform ini adalah [penjagaan sendiri] dan tidak akan pernah mempercayakan aset pengguna kepada pengendali berpusat",
        "d7": "Akses bebas pada bila-bila masa",
        "d8": "Akses bebas membolehkan dana lebih fleksibel dan mudah",
        "d9": "Protokol stablecoin",
        "d10": "Gunakan stablecoin untuk meminimumkan volatiliti mata wang kripto. Syiling ini sering diikat pada mata wang stabil seperti dolar AS, menjadikannya sebagai medium pertukaran dan penyimpan nilai yang boleh diterima"
    }
    
}