export default {
    common: {
        title: 'DeFi Wallet',
        symbol: '$',
        currency: 'dollars',
        login: 'login',
        lang1: 'Just now',
        lang2: ' minutes ago',
        lang3: ' hours ago',
        lang4: 'Yesterday',
        lang5: 'My Assets',
        lang6: 'Yesterday\'s Earnings',
        lang7: 'View More',
        lang8: 'DeFi',
        lang9: 'Fund',
        lang10: 'Favorites',
        lang11: 'Highest 7-Day Annualized Return',
        lang12: 'Low Risk',
        lang13: 'Medium Low Risk',
        lang14: 'Medium High Risk',
        lang15: 'High Risk',
        lang16: ' 3 Years',
        lang17: '1 Year',
        lang18: 'Accumulated Earnings',
        lang19: 'Holding',
        lang20: 'Cancel',
        lang21: 'No data available at the moment',
        lang22:'confirm',
        lang23:'Cancel',
        lang24:'No more',
        lang25: 'Language',
        lang26:'No card bound',
        lang27:'Order details',
        lang28:'Scan the QR code and add me as a friend',
        lang29:'Received successfully',
        lang30:'Copy success',
        lang31: 'Ghi chú'

    },
    shareCard: {
        lang1: 'gợi ý',
        lang2: 'xác nhận',
        lang3: 'Thêm bưu thiếp sẽ xóa tất cả các thẻ, bạn có chắc chắn muốn thêm chúng không?',
        lang4: 'Thêm ảnh sẽ xóa thẻ ảnh, bạn có chắc chắn thêm không?',
        lang5: 'Tỏa sáng',
        lang6: 'Hoàn thành',
    },
    tabbar: {
        lang1: 'Hôm nay',
        lang2: 'Quỹ',
        lang3: 'của tôi'
    },
    login: {
        lang1: 'Tạo tài khoản mới',
        lang2: 'Đã có tài khoản',
        lang3: 'Liên hệ bộ phận dịch vụ khách hàng',
        lang4: 'Đăng nhập trái phép, vui lòng ủy quyền lại'
    },
    home: {
        lang1: 'Ưu tiên cho người dùng mới',
        lang2: 'Không bao giờ mất tiền và rút tiền bất cứ lúc nào',
        lang3: 'Mua một khoản tiền và dùng thử',
        lang4: 'Nhắc nhở bán tài sản khan hiếm',
        lang5: 'Nhấn và giữ để theo dõi tài khoản',
        lang6: 'WeChat chính thức',
        lang7: 'Số lượng người dùng đầu tư vượt quá 400 triệu và tài sản được quản lý vượt quá 2 nghìn tỷ',
        lang8: 'Rất đa dạng',
        lang9: 'Giao dịch và mua hàng chục nghìn trái phiếu, quỹ và hợp đồng tương lai trên khắp thế giới',
        lang10: 'bảo mật',
        lang11: 'Đội ngũ kiểm soát rủi ro được bản địa hóa trên toàn cầu nhằm ngăn chặn nghiêm ngặt các rủi ro về tài khoản và vốn',
        lang12: 'Chi tiết thu nhập'
    },
    balance: {
        lang1: 'Lịch sử kiếm tiền dương mỗi ngày, rút ​​tài khoản nhanh chóng',
        lang2: 'Xem chi tiết',
        lang3: 'Tổng số tiền',
        lang4: 'Bán',
        lang5: 'mua',
        lang6: 'bàn thảo luận',
        lang7: '4 đặc điểm chính của DeFi',
        lang8: 'Thu nhập ổn định',
        lang9: 'Đa năng',
        lang10: 'lấy ra bất cứ lúc nào',
        lang11: 'bảo mật',
        lang12: 'Chi tiết giao dịch',
        lang13: 'Chi tiết thu nhập',
        lang14: 'Số tiền chuyển',
        lang15: 'Có thể chuyển ra ngoài',
        lang16: 'Xin vui lòng nhập số tiền',
        lang17: 'Thao tác đã hoàn tất',
        lang18: 'Số tiền chuyển',
        lang19: 'Đã được thông báo về cảnh báo rủi ro và đồng ý với thỏa thuận dịch vụ',
        lang20: 'Vui lòng kiểm tra thỏa thuận dịch vụ',
        lang21: 'có thể được chuyển nhượng',
    },
    comment: {
        lang1: '"Diễn đàn thảo luận Yue Bao"',
        lang2: 'Chi tiết sản phẩm',
        lang3: 'mới nhất',
        lang4: 'bài nóng',
        lang5: 'Đã đăng',
        lang6: 'Bày tỏ ý kiến',
        lang7: 'Xuất bản',
        lang8: 'Thêm ý kiến ​​của bạn',
        lang9: 'bức tranh',
        lang10: 'biểu thức',
        lang11: 'Nội dung không được để trống',
        lang12: 'Đã xuất bản thành công',
    },
    fund:{
        a1:'Giới thiệu về chúng tôi',
        a2:'Thu nhập',
        a3:'Tổng số tiền đã mua',
        a4:'Thu nhập của ngày hôm qua',
        a5:'Tổng thu nhập',
        a6:'Bảo vệ chính',
        a7:'Xem thêm',
        a8: 'Phân bổ cân bằng giữa nhiều ngành, hiệu quả tiếp tục vượt trội so với thị trường',
        a9:'Lựa chọn tích cực',
        a10: 'Được hơn 310.000 người dùng phê duyệt',
        a11:'Siêu thị 8 năm liên tiếp',
        a12:'Tối ưu hóa định lượng được hỗ trợ bởi AI',
        a13:'Nhiều bố cục chính xác',
        a14:'Tập trung vào phong cách vốn hóa vừa và nhỏ',
        a15: 'Sắp xếp các doanh nghiệp trả cổ tức cao',
        a16:'Mức tăng trong 5 năm vượt quá 100%',
        a17:'Lựa chọn số lượng cổ phiếu',
        a18:'Tăng và giảm trong ba năm qua',
        a19:'Tăng và giảm trong năm qua',
        a20:'rủi ro thấp',
        a21:'rủi ro trung bình',
        a22:'rủi ro trung bình cao',
        a23:'Rủi ro cao',
        a24:'Lợi nhuận tích lũy cho người dùng',
        a25:'kể từ',
        a26:'Quyền đổi linh hoạt',
        a27:'Khuyến nghị của chuyên gia',
        a28:'Theo đuổi thu nhập hàng năm',
        a29:'Mức độ mạnh mẽ',
        a30:'Phước lành dữ liệu lớn',
        a31:'chu kỳ ngắn',
        a32:'Giải quyết trong cùng ngày',
        a33:'Có lợi nhuận cao',
        a34:'DOANH NGHIỆP NHƯ TRUSTWORLD FISCAL CỦA BẠN',
        a35:'Kể từ năm 2004, gần 1.000.000 người dùng trên toàn thế giới đã tạo ra doanh thu 8 tỷ USD thông qua WORLD FISCAL và các thương hiệu đối tác của nó. Tiền của bạn được bảo vệ bởi các tổ chức tài chính hàng đầu'
    },
    fundPurchase:{
        b1:"Tencent Teng'an",
        b2:'Số tiền mua',
        b3:'Quy tắc giao dịch',
        b4:'Số tiền bạn có thể mua tiền là:',
        b5:'Tỷ giá mua',
        b6:'Xin vui lòng nhập mật khẩu thanh toán',
        b7:'Mật khẩu có 6 chữ số',
        b8: 'Tôi biết cảnh báo rủi ro và đồng ý với thỏa thuận dịch vụ',
        b9:'Mua hàng thành công',
        b10:'Nhập sai',
        b11:'Xin vui lòng đánh dấu vào ô đồng ý',
        b12:'Chọn mua',
        b13:'Mua bắt đầu từ 1 nhân dân tệ',
        b14:"Lợi nhuận dự kiến:",
        b15:"Phương thức thanh toán:",
        b16:"Ngày kết thúc",
        b17: "Thanh toán khi đáo hạn",
        b18:"Thời lượng chu kỳ:",
        b19:"Day",
        b20:"Mô tả chi tiết:",
        b21:"Chưa có lời giải thích",
       


    },
    user:{
        c1:'Giới thiệu về chúng tôi',
        c2:'Giới thiệu về chúng tôi',
        c3:'Chào mừng đến với hành trình làm giàu',
        c4:'Tài sản của tôi',
        c5:'Tiền dùng thử',
        c6:'Tài sản linh hoạt',
        c7:'Nội dung âm thanh',
        c8:'Tài sản cấp cao',
        c9:'Lợi nhuận ngắn hạn',
        c10:'Lợi nhuận ổn định',
        c11:'Theo đuổi lợi nhuận',
        c12:'Dịch vụ công cụ',
        c13:'Ghi lại giao dịch',
        c14:'Bản ghi số dư',
        c15:'Hồ sơ Quỹ',
        c16:'Thêm thông tin',
        c17:'Bảo mật tài khoản',
        c18:'Dịch vụ khách hàng của tôi',
        c19:'Giới thiệu về chúng tôi',
        c20:'Rút tiền',
        c21:'Nạp tiền',
    },
  
    accountChanges:{
        d1:'Giới thiệu về chúng tôi',
        d2:'Bản ghi thay đổi tài khoản'
    },
    funChanges:{
        e1:'Hồ sơ giao dịch quỹ',
        e2:'đang tiến hành',
        e3:'Đã hết hạn',
        e4:'Thời gian còn lại',
        e5:'day',
        e6:'Chi tiết đơn hàng',
        e7:'Trạng thái:',
        e8:'đang tiến hành',
        e9:'Đã kết thúc',
        e10:'Việc giải quyết bị đình chỉ',
        e11:'Thời lượng chu kỳ',
        e12:'Tên sản phẩm',
        e13:'Mã đơn hàng',
        e14:'Số lượng mua',
        e15:'Thu nhập tích lũy',
        e16:'Phương pháp giải quyết',
        e17:'Mô tả sản phẩm',
        e18:'Thời gian bắt đầu',
        e19:'Thời gian kết thúc',
        e20:'Số ngày còn lại',
    },
    userInfo:{
        f1:'Thông tin cá nhân',
        f2:'Nam',
        f3:'nữ',
        f4:"Tuổi",
        f5:'Hãy chọn một quốc gia',
        f6:'Tên ngân hàng',
        f7:'Số thẻ ngân hàng',
        f8: "Xin vui lòng đặt mật khẩu giao dịch",
        f9:'Xác nhận mật khẩu giao dịch',
        f10:'Xin vui lòng nhập',
        f11:'Xin vui lòng nhập lại',
        f12:'Gửi',
        f13:''
    },
    fundDetails:{
        e1:'Giới thiệu về chúng tôi',
        e2:'rủi ro thấp',
        e3:'rủi ro trung bình',
        e4:'rủi ro trung bình đến cao',
        e5:'Rủi ro cao',
        e6:'Mua tối thiểu Nhân dân tệ',
        e7:'Thiết lập sự gia tăng',
        e8:'Tăng trong năm qua',
        e9:'Tăng trong ba năm qua',
        e10:'Mua ngay',
        e11:'Thành tích lịch sử',
        e12: 'Chuyển 10.000 nhân dân tệ và thu nhập hàng ngày trong 7 ngày qua như sau',
        e13:'Mua hàng'
    },
    bankCard:{
        a1:'Thêm thẻ ngân hàng',
        a2:'Chọn quốc gia',
        a3:'Chọn ngân hàng',a4:'Số thẻ ngân hàng',
        a5:'Xin vui lòng nhập số thẻ ngân hàng của bạn',
        a6:'Chủ thẻ',
        a7: 'Xin vui lòng nhập tên chủ thẻ',
        a8:'Xem thẻ ngân hàng bị ràng buộc',
        a9:'ràng buộc',
        a10:'Xin vui lòng nhập mật khẩu thanh toán',
        a11:'Liên kết thành công',
        a12:'Đầu vào sai',
        a13:'Thẻ của tôi',
        a14:'Bạn chưa đóng thẻ bảo mật',
        a15:'Sửa đổi thẻ ngân hàng',
        a16:'Thẻ bảo mật',
        a17:'Trở thành thẻ an toàn',
        a18: 'Thẻ dùng để mua sản phẩm tài chính lần đầu sẽ trở thành thẻ an toàn',
        a19:'Tháo thẻ bảo mật',
        a20: 'Thẻ bảo mật được liên kết với tài khoản tài chính và tiền từ ngân hàng chỉ có thể được rút từ thẻ bảo mật',
        a21:'An toàn và đảm bảo',
        a22: 'Ngay cả khi điện thoại của bạn bị mất, tiền chỉ có thể được rút vào thẻ bảo mật của bạn',
        a23:'Thay thẻ bảo mật',
        a24: 'Sau khi vượt qua đợt đánh giá chính thức, thẻ bảo mật có thể được thay thế',
        a25: 'Tên chi nhánh',
        a26: 'Số chi nhánh',
        a27: 'Bút danh',
        a28: 'Chọn Mạng',
        a29: 'Địa Chỉ',
        a30: 'Vui Lòng Nhập Địa Chỉ',

    },
    accountChanges:{
        a1:'thay đổi tài khoản',
        a2:'gõ',
        a3:'Chọn loại',
        a4:'Mã đơn hàng',
        a5:'số tiền',
        a6:'Số dư trước khi mua',
        a7:'Số dư sau khi mua hàng',
        a8:'thời gian',
        a9:'Mã đơn hàng',
        a10:'Rút tiền',
        a11:'Đóng băng',
        a12:"Chuyển đến Yu'E Bao",
        a13:'Mua quỹ',
        a14:'khấu trừ',
        a15:'Phần thưởng đăng nhập hàng ngày',
        a16:'Nạp tiền Alipay',
        a17:'Hết hạn quản lý tài chính',
        a18:"Chuyển ra khỏi Yu'E Bao",
        a19:'Giải phóng',
        a20:'Rút tiền không thành công',
        a21:'Đăng ký miễn phí',
        a22:'Hoạt động',
        a23:'Thu nhập quản lý tài chính hàng ngày',
        a24:'Lợi nhuận sản phẩm có chu kỳ',
        a25:'quà tặng',
        a26:'Thu Nhập Giữ Chỗ'
    },
    setPwd:{
        a1:'Đặt mật khẩu giao dịch',
        a2:'Mật khẩu giao dịch',
        a3:'Xin vui lòng nhập mật khẩu giao dịch gồm 6 chữ số',
        a4:'Xác nhận mật khẩu giao dịch',
        a5:'Xin vui lòng nhập lại mật khẩu giao dịch',
        a6:'Xin vui lòng nhập mật khẩu giao dịch gồm 6 chữ số',
        a7:'Lưu',
        a8:'Đặt thành công',
        a9: 'Nếu đầu vào của bạn có lỗi, vui lòng nhập lại',
    },
    Withdraw:{
        a2:'Rút tiền',
        a3:'Số tiền rút',
        a4:'Mật khẩu giao dịch',
        a5:'Nhập mật khẩu giao dịch',
        a6:'Xem hồ sơ rút tiền',
        a7:'Rút tiền',
        a8:'Rút tiền mặt',
        a9:'Số tiền mặt có thể rút'
    },
    WithdrawRecord:{
        e1:'Hồ sơ giao dịch quỹ',
        e2:'đang tiến hành',
        e3:'Ghi âm',
        e4:'Rút tiền không thành công',
        e5:'Đang xem xét',
        e6:'Chi tiết đơn hàng',
        e7:'Trạng thái:',
        e8:'Đang xem xét',
        e9:'Đã kết thúc',
        e10:'Việc giải quyết bị đình chỉ',
        e11:'Địa chỉ rút tiền',
        e12:'Tên ngân hàng',
        e13:'Chủ thẻ',
        e14:'tiền tệ',
        e15:'Thời gian sáng tạo',
        e16:'Rút tiền thành công',
        e17:'Cập nhật thời gian',
        e18:'Số tiền'
    },
    aboutas:{
        a1:"Về Chúng Tôi",
        a2:"WorldFirst FOF được thành lập tại London, Anh vào năm 2004 và từ đó đã cung cấp dịch vụ chất lượng cao cho người dùng trên toàn thế giới. Chúng tôi phụ thuộc vào mạng lưới sinh thái tài chính vượt quốc gia, cung cấp cho khách hàng của chúng tôi các giải pháp sản phẩm phong phú và bảo đảm an toàn tài chính.",
        a3: "Giấy phép Tài chính",
        a4:"Chúng tôi có đủ điều kiện để mở rộng toàn cầu thông qua sở hữu hoặc hợp tác với các cơ quan thương hiệu địa phương ở nhiều quốc gia và khu vực bao gồm Mỹ, Anh, Canada, Hà Lan, Nhật Bản, Trung Quốc đại lục, Hồng Kông, Malaysia, Singapore và Úc, cung cấp bảo đảm mạnh mẽ cho an toàn tài chính của khách hàng và hỗ trợ mở rộng toàn cầu của khách hàng!",
        a5: "Tuân thủ an toàn Quỹ",
        a6: "Yêu cầu Tuân thủ",
        a7: "Chúng tôi đã thành lập một nhóm tuân thủ tiêu chuẩn quốc gia, tuân thủ đầy đủ các tiêu chuẩn quốc tế chống rửa tiền và tài chính khủng bố, tuân thủ đầy đủ các luật và quy định liên quan và thực hiện các biện pháp cần thiết để ngăn chặn các rủi ro tài chính.",
        a8: "Bảo vệ Dữ liệu Quyền riêng tư",
        a9: "Thông tin và quyền riêng tư được bảo vệ bởi chính sách bảo mật nghiêm ngặt, quản lý tỉ mỉ, có rủi ro lạm dụng tối thiểu, mã hóa kỹ thuật cho bất kỳ chuyển giao dữ liệu nào và hạn chế truy cập với các điều khiển vật lý nghiêm ngặt.",
        a10: "Bảo đảm Giao dịch Quỹ",
        a11: "Tất cả các hoạt động phải được thực hiện theo chỉ dẫn của khách hàng. Nếu không được ủy quyền, bên thứ ba không thể thao túng quỹ khách hàng.",
        a12: "Đầu tư EMC Toàn cầu",
        a13: "WorldFirst FOF có văn phòng tại 49 thành phố thuộc 18 quốc gia trên toàn thế giới, cung cấp các dịch vụ chuyên nghiệp tùy chỉnh hơn.",
        a14: "Đăng ký ngay, mở tài khoản và liên kết cơ hội kinh doanh toàn cầu."
    },
    recharge: {
        r1: 'Bảo đảm An toàn Quỹ',
        r2: 'Đền bù quỹ lên đến một triệu đồng',
        r3: 'Chuyển khoản ngay lập tức',
        r4: 'Không phí giao dịch',
        r5: 'Rút tiền bất kỳ lúc nào',
        r6: 'Số Tiền Nạp',
        r7: 'Nạp Ngay',
        r8: 'Tại Sao Chọn Chúng Tôi',
        r9: 'Đầu Tư Đa Dạng',
        r10: 'Lợi Nhuận Cao',
        r11: 'Nhiều Hướng Dẫn Viên',
        r12: 'Không Thiệt Hại',
        r13: 'Nạp Tiền',
        r14: 'Phương Thức Thanh Toán',
        r15: 'Nhập Số Tiền',
        r16: 'Vui lòng chọn',
        r17: 'Lịch Sử Nạp Tiền',
        r18: 'Phương Thức Thanh Toán',
        r19: 'Vui lòng chọn một quốc gia',
        r20: 'Liên Kết Thanh Toán',
        r21: 'Vui lòng sao chép và mở trong trình duyệt',
        r22: 'Đã Thanh Toán',
        r23: 'Sao chép',
        r24: 'Sao chép thành công!',
        r25: 'Đang Xử Lý',
        r26: 'Nạp Thành Công',
        r27: 'Nạp Thất Bại'
    },
    invite: {
        i1: 'Yêu cầu phần thưởng',
        i2: 'Vui lòng nhập mã mời',
        i3: 'Vui lòng nhập mã mời đúng'
    },
    home1: {
        "h1": "KIẾM TIỀN NHƯ MỘT CHUYÊN GIA",
        "h2": "Rút tiền nhanh chóng",
        "h3": "Hoạt động chuyên nghiệp",
        "h4": "Lợi nhuận nhanh chóng",
        "h5": "1. Thu nhập cùng ngày, không phí giao dịch",
        "h6": "2. 20 năm tăng trưởng ổn định và không mất mát",
        "h7": "3. Giám sát chuyên nghiệp 24 giờ về điều kiện thị trường",
        "h8": "Xây dựng cho Sự phát triển và Hoài bão",
        "h9": "Hoán đổi tiền tệ, Quản lý Nhóm, Kế toán và",
        "h10": "Nhiều - Tất cả trong một nơi.",
        "h11": "Quản lý Rủi ro Hoán đổi Tiền tệ",
        "h12": "Ủy quyền Đa người dùng",
        "h13": "Tích hợp Sinh thái",
        "h14": "Về WORLD FIRST",
        "h15": "20 Năm Lịch sử",
        "h16": "Sự Tin cậy của Khách hàng",
        "h17": "Vết chân toàn cầu",
        "h18": "Giá trị Giao dịch",
        "h19": "Nghe Những gì Khách hàng của chúng tôi nói",
        "h20": "Hoán đổi Tiền tệ, Quản lý Nhóm, Kế toán và",
        "h21": "Nhiều - Tất cả trong một nơi.",
        "h22": "Peter Keen",
        "h23": "Giám đốc Điện",
        "h24": "Sử dụng WORLD FISCAL mang lại sự an tâm cho tôi, vì tôi biết trách nhiệm của cộng đồng chúng tôi là được tôn trọng và bảo vệ, giữ cho lợi nhuận và tài sản của tôi an toàn",
        "h25": "Raffi Schier",
        "h26": "Giám đốc ngân hàng Malaysia",
        "h27": "Tôi đã mua sản phẩm ngân hàng tại Malaysia và thấy nó rất tốn thời gian và thách thức, nhưng so với đó, việc mở tài khoản thông qua WORLD FISCAL rất dễ dàng và hiệu quả",
        "h28": "Monica Milcarz",
        "h29": "Giám đốc nhóm Yingfa",
        "h30": "Yu'e Bao là sản phẩm ổn định nhất mà tôi từng mua. Nó giúp tôi vượt qua những thời kỳ khó khăn nhất và khiến tôi trở thành người tốt hơn bây giờ."
    },
    webLogin: {
        "w1": "Đăng nhập",
        "w2": "Đăng ký",
        "w3": "Tài khoản",
        "w4": "Mật khẩu",
        "w5": "Quốc gia",
        "w6": "Thành phố",
        "w7": "Vui lòng nhập tài khoản",
        "w8": "Vui lòng nhập mật khẩu",
        "w9": "Đăng nhập thành công",
        "w10": "Đăng ký thành công",
        "w11": 'Bắt đầu bằng chữ cái, Chiều dài 6 ~ 18',
        "w12": 'Chiều dài 6 ~ 18',
        "w13": 'Login with LINE',
        "w14": 'Login with facebook',
    },
    vip: {
        v1: 'Output',
        v2: 'Wallet',
        v3: 'award',
        v4: 'Để biết thêm phần thưởng, vui lòng liên hệ dịch vụ khách hàng',
        v5: 'Khuyến mãi này sẽ không kéo dài mãi mãi. Tham gia ngay để nhận được phần thưởng hậu hĩnh, nhưng hãy chắc chắn tuân theo các điều khoản và điều kiện. Vui lòng truy cập trang web của chúng tôi để biết thêm thông tin.'
    },
    defi: {
        "d1": "Có cổ tức với các giao dịch",
        "d2": "Sản phẩm và tính năng",
        "d3": "Lợi nhuận ổn định",
        "d4": "Nền tảng thường cung cấp lãi suất cao hơn so với các sản phẩm ngân hàng truyền thống",
        "d5": "Loại bỏ rủi ro",
        "d6": "Nền tảng là [tự lưu ký] và sẽ không bao giờ ủy thác tài sản của người dùng cho nhà điều hành tập trung",
        "d7": "Truy cập bất cứ lúc nào",
        "d8": "Truy cập bất cứ lúc nào cho phép quỹ linh hoạt và thuận tiện hơn",
        "d9": "Giao thức stablecoin",
        "d10": "Sử dụng stablecoin để giảm thiểu sự biến động của tiền điện tử. Những đồng tiền này thường được neo vào các đồng tiền ổn định như đô la Mỹ, khiến chúng trở thành phương tiện trao đổi và lưu trữ giá trị chấp nhận được"
    }
}