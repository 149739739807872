export default {
  common: {
    title: "ウェルスパス",
    symbol:"$",
    currency: "USD",
    login: "ログイン",
      lang1: "最近",
      lang2: "分前",
      lang3: "数時間前",
      lang4: "昨日",
      lang5: "私の資産",
      lang6: "昨日の収益",
      lang7: "もっと見る",
      lang8: "DeFi 貯蓄",
      lang9: "基金",
      lang10: "オプション",
      lang11: "毎日の収入",
      lang12: "リスク0",
      lang13: "低リスク",
      lang14: "中リスク",
      lang15: "高リスク",
      lang16: "過去 3 年間の増減",
      lang17: "過去1年間の増減",
      lang18: "累計収入",
      lang19: "この製品を購入する",
      lang20: "キャンセル",
      lang21: "まだデータがありません",
      lang22:"確認",
      lang23:"キャンセル",
      lang24:"もうだめ",
      lang25: "言語",
      lang26:"カードがバインドされていません",
      lang27:"注文の詳細",
      lang28:"QR コードをスキャンして友達として追加してください",
      lang29:"正常に受信しました",
      lang30:'Copy success',
      lang31: '備考'


     
  },
  shareCard: {
      lang1: "ヒント",
      lang2: "確認",
      lang3: "ポストカードを追加するとすべてのカードが削除されます。追加してもよろしいですか?",
      lang4: "写真を追加すると写真カードが削除されます。追加してもよろしいですか?",
      lang5: "シャイン",
      lang6: "完了",
  },
  tabbar: {
      lang1: "今日",
      lang2: "ファンド",
      lang3: "私の"
  },
  login: {
      lang1: "新しいアカウントを作成する",
      lang2: "すでにアカウントをお持ちです",
      lang3: "カスタマーサービスに連絡してください",
      lang4: "不正なログインです。再認証してください"
  },
  home: {
      lang1: "新規ユーザーに推奨",
      lang2: "お金を失うことはなく,いつでもお金を引き出すことができます",
      lang3: "金額を買って試してみる",
      lang4: "希少資産売却リマインダー",
      lang5: "アカウントをフォローするには長押ししてください",
      lang6: "WeChat公式",
      lang7: "投資利用者数は4億人を超え,運用資産は2兆を超える",
      lang8: "多種多様",
      lang9: "世界中で何万もの債券,ファンド,先物を取引および購入",
      lang10: "セキュリティ",
      lang11: "アカウントと資本のリスクを厳密に防止するためのグローバルにローカライズされたリスク管理チーム",
      lang12: "収入の詳細"
  },
  balance: {
      lang1: "毎日歴史的にプラスの収益,素早い口座引き出し",
      lang2: "詳細を表示",
      lang3: "合計金額",
      lang4: "販売",
      lang5: "購入",
      lang6: "ディスカッション掲示板",
      lang7: "DeFi 貯蓄の4大特徴",
      lang8: "安定した収入",
      lang9: "多用途",
      lang10: "いつでも取り出してください",
      lang11: "セキュリティ",
      lang12: "トランザクションの詳細",
      lang13: "収入の詳細",
      lang14: "送金額",
      lang15: "転送可能",
      lang16: "金額を入力してください",
      lang17: "操作は完了しました",
      lang18: "送金額",
      lang19: "リスク警告を通知され,サービス契約に同意しました",
      lang20: "サービス契約を確認してください",
      lang21: "転送可能",
  },
  comment: {
      lang1: "越宝ディスカッション フォーラム",
      lang2: "製品の詳細",
      lang3: "最新",
      lang4: "ホットポスト",
      lang5: "投稿済み",
      lang6: "意見を表明する",
      lang7: "公開",
      lang8: "意見を追加してください",
      lang9: "画像",
      lang10: "式",
      lang11: "コンテンツを空にすることはできません",
      lang12: "正常に公開されました",
  },
  fund:{
      a1:"当社について",
      a2:"収入",
      a3:"購入したファンドの総数",
      a4:"昨日の収益",
      a5:"合計所得金額",
      a6:"元本保護",
      a7:"もっと見る",
      a8: "複数の業界をバランスよく配分し,市場を上回る業績を継続",
      a9:"アクティブな選択",
      a10: "310,000 人以上のユーザーによって承認されました",
      a11:"8年連続スーパーマーケット",
      a12:"AI 支援による定量化の最適化",
      a13:"複数の正確なレイアウト",
      a14:"中小型株のスタイルに注目",
      a15: "高配当企業を配置する",
      a16:"5年間の増加率は100%を超えました",
      a17:"定量的な銘柄選択",
      a18:"過去3年間の増減",
      a19:"過去1年間の増減",
      a20:"低リスク",
      a21:"中リスク",
      a22:"中～高リスク",
      a23:"高リスク",
      a24:"ユーザーの累計利益",
      a25:"現在",
      a26:"柔軟な償還",
      a27:"専門家の推奨",
      a28:"年収の追求",
      a29:"堅牢レベル",
      a30:"ビッグデータの祝福",
      a31:"短いサイクル",
      a32:"即日決済",
      a33:"収益性が高い",
      a34:'あなたのトラストワールドのようなビジネス',
        a35:'2004 年以来、世界中で約 1,000,000 人のユーザーが WORLD FISCAL とそのパートナー ブランドを通じて 80 億ドルの収益を上げています。あなたの資金は大手金融機関によって保護されています。'
    },
  fundPurchase:{
      b1:"テンセントテンアン",
      b2:"購入金額",
      b3:"取引ルール",
      b4:"資金を購入できる金額は:",
      b5:"購入レート",
      b6:"支払いパスワードを入力してください",
      b7:"パスワードは6桁です",
      b8: "リスク警告を承知しており,サービス契約に同意します",
      b9:"購入成功",
      b10:"入力が間違っています",
      b11:"同意するにはボックスにチェックを入れてください",
      b12:"購入を選択してください",
      b13:"1元から購入",
      b14:'期待利益:',
      b15:"決済方法:",
      b16:"一日の終わり",
      b17: "満期決済",
      b18:"サイクル期間:",
      b19:"天",
      b20:"詳細な説明:",
      b21:"まだ説明がありません",
     


  },
  user:{
      c1:"私たちについて",
      c2:"私たちについて",
      c3:"富の旅へようこそ",
      c4:"私の資産",
      c5:"お試し金",
      c6:"柔軟な資産",
      c7:"サウンドアセット",
      c8:"高レベルの資産",
      c9:"短期返品",
      c10:"安定した利益",
      c11:"利益の追求",
      c12:"ツールサービス",
      c13:"取引記録",
      c14:"残高記録",
      c15:"資金記録",
      c16:"詳細情報",
      c17:"アカウントのセキュリティ",
      c18:"私のカスタマーサービス",
      c19:"私たちについて",
      c20:"撤退",
      c21:"リチャージ",
  },

  accountChanges:{
      d1:"私たちについて",
      d2:"アカウント変更記録"
  },
  funChanges:{
      e1:"資金取引記録",
      e2:"進行中",
      e3:"期限切れ",
      e4:"残り時間",
      e5:"天",
      e6:"注文の詳細",
      e7:"ステータス:",
      e8:"進行中",
      e9:"終了",
      e10:"決済一時停止",
      e11:"サイクル期間",
      e12:'製品名',
        e13:'注文番号',
        e14:'購入数量',
        e15:'累計収入',
        e16:'決済方法',
        e17:'商品説明',
        e18:'開始時刻',
        e19:'終了時間',
        e20:'残り日数',
  },
  userInfo:{
      f1:"個人情報",
      f2:"男性",
      f3:"女性",
      f4:"年齢",
      f5:"国を選択してください",
      f6:"銀行名",
      f7:"銀行カード番号",
      f8: "取引パスワードを設定してください",
      f9:"トランザクションパスワードの確認",
      f10:"入力してください",
      f11:"もう一度入力してください",
      f12:"送信",
      f13:""
  },
  fundDetails:{
      e1:"私たちについて",
      e2:"低リスク",
      e3:"中リスク",
      e4:"中リスクから高リスク",
      e5:"高リスク",
      e6:"人民元の最低購入額",
      e7:"増加を確立",
      e8:"過去1年間の増加",
      e9:"過去 3 年間の増加",
      e10:"今すぐ購入",
      e11:"過去のパフォーマンス",
      e12: "10,000元を送金し,過去7日間の1日の収入は次のとおりです",
      e13:"購入"
  },
  bankCard:{
      a1:"銀行カードを追加",
      a2:"国を選択",
      a3:"バンクを選択",
      a4:"銀行カード番号",
      a5:"キャッシュカード番号を入力してください",
      a6:"口座名義",
      a7: "口座名義の名前を入力してください",
      a8:"バインドされた銀行カードを表示",
      a9:"バインド",
      a10:"支払いパスワードを入力してください",
      a11:"バインド成功",
      a12:'入力が間違っています',
      a13:"私のカード",
      a14:"セキュリティカードをまだバインドしていません。",
      a15:"キャッシュカードを変更する",
      a16:"セキュリティカード",
      a17:"安全なカードになります",
      a18: "初めて金融商品を購入する際に使用するカードはセキュアカードになります",
      a19:"セキュリティカードを取り外してください",
      a20: "セキュリティ カードは金融口座に関連付けられており,銀行からの資金はセキュリティ カードからのみ引き出すことができます。",
      a21:"安全で保証されています",
      a22: "携帯電話を紛失した場合でも,資金はセキュリティカードにのみ引き落とされます。",
      a23:"セキュリティカードを交換してください",
      a24: "正式な審査に合格した後,セキュリティカードの交換が可能です",
      a25: '支店名',
      a26: '支店番号',
      a27: '口座名義(カタ)',
      a28: 'ネットワークを選択',
      a29: 'アドレス',
      a30: 'アドレス 入力してください',

  },
  accountChanges:{
      a1:"アカウント変更",
      a2:"タイプ",
      a3:"タイプを選択",
      a4:"注文番号",
      a5:"金額",
      a6:"購入前の残高",
      a7:"購入後の残高",
      a8:"時間",
      a9:"注文番号",
      a10:"撤退",
      a11:"フリーズ",
      a12:"余額宝へ転送",
      a13:"ファンドを購入",
      a14:"控除",
      a15:"毎日のログイン報酬",
      a16:"Alipay リチャージ",
      a17:"財務管理の期限が切れます",
      a18:"余額宝からの転送",
      a19:"解凍",
      a20:"出金に失敗しました",
      a21:"無料で登録してください",
      a22:"アクティビティ",
      a23:"日々の財務管理収入",
      a24:'循環型商品の利益',
      a25:"ギフト",
      a26:'保有収益'
  },
  setPwd:{
      a1:'取引パスワードを設定',
      a2:"取引パスワード",
      a3:"6桁の取引パスワードを入力してください",
      a4:"取引パスワードを確認してください",
      a5:"取引パスワードをもう一度入力してください",
      a6:"6桁の取引パスワードを入力してください",
      a7:"保存",
      a8:"正常に設定されました",
      a9: "入力内容に誤りがある場合は,再入力してください。"
  },
  Withdraw:{
      a2:"撤退",
      a3:"出金金額",
      a4:"取引パスワード",
      a5:"取引パスワードを入力してください",
      a6:"出金記録の表示",
      a7:"撤退",
      a8:"現金を引き出す",
      a9:"引き出し可能な現金の金額"
  },
  WithdrawRecord:{
      e1:'資金取引記録',
      e2:"進行中",
      e3:'記録',
      e4:'出金に失敗しました',
      e5:"検討中",
      e6:'注文の詳細',
      e7:'ステータス:',
      e8:"検討中",
      e9:'終了',
      e10:'決済一時停止',
      e11:'出金アドレス',
      e12:'銀行名',
      e13:"口座名義",
      e14:'通貨',
      e15:'作成時間',
      e16:'引き出しに成功しました',
      e17:'更新時間',
      e18:"金額"
  },
  aboutas:{
        a1:"私たちについて",
        a2:"worldfirst FOFは2004年に英国ロンドンで設立されて以来、世界中のユーザーにより質の高いサービスを提供し、国境を越えた金融分野の世界生態協力ネットワークに依存して、私たちのお客様により豊富な製品ソリューションと資金安全保障を提供してきました。",
        a3: "金融ライセンス認証",
        a4:"米国、英国、カナダ、オランダ、日本、中国本土、中国香港、マレーシア、シンガポール、オーストラリアなど多くの国と地域で所有しているか、または現地のブランド保有機関と協力する方式を通じて、グローバル展業の資質を備えており、顧客の資金安全のために堅固な保障を提供し、顧客のグローバル進出に付き添う！",
        a5: "資金安全コンプライアンスの監督管理",
        a6: "規制コンプライアンス要件",
        a7: "国家レベルの高基準コンプライアンスチームを設立し、国際的な反マネーロンダリング、対テロ融資基準を厳格に遵守し、関連する法律・法規の要求を厳格に遵守し、金融リスクを防止する。",
        a8: "プライバシーデータ保護",
        a9: "情報とプライバシーはプライバシーポリシーによって保護され、厳格に管理され、悪用のリスクを最小限に抑え、いかなるデータ転送にも技術暗号化があり、アクセスは厳格な物理制御に制限されている。",
        a10: "資金取引保障",
        a11: "すべての操作は、お客様の命令によって実行される必要があります。承認されていない場合、第三者はお客様の資金を操作することはできません。",
        a12: "EMCのグローバル分布",
        a13: "worldfirst FOFは、世界18カ国49都市にオフィスを設置し、よりローカライズされたプロフェッショナルサービスを提供しています。",
        a14: "今すぐ登録して、アカウントを開設して、世界のビジネスチャンスをリンクします"
    },
  recharge: {
    r1: "資金は安全に保管されています",
    r2: "最高補償額は100万ドル",
    r3: 'リアルタイム到着',
    r4: '手数料はかかりません',
    r5: 'いつでもお金を引き出す',
    r6: 'チャージ量',
    r7: '今すぐ充電してください',
    r8: "当社を選んだ理由",
    r9: "分散投資",
    r10: 'より高い収益',
    r11: "大量の家庭教師",
    r12: '損失0',
    r13: 'リチャージ',
    r14: 'リチャージ方法',
    r15: '金額を入力してください',
    r16: 'リチャージ方法を選択してください',
    r17: 'リチャージレコード',
    r18: '支払い方法',
    r19: '国を選択してください',
    r20: '支払いリンク',
    r21: 'コピーしてブラウザで開く',
    r22: '支払い済み',
    r23: 'コピー',
    r24: 'コピーが成功しました！',
    r25: '処理中',
    r26: 'チャージ成功',
    r27: 'チャージ失敗'
  },
    invite: {
        i1: '報酬を請求する',
        i2: '招待コードを入力してください',
        i3: '正しい招待コードを入力してください'
    },
    home1: {
        "h1": "プロのようにお金を稼ぐ",
        "h2": "迅速な引き出し",
        "h3": "プロの運営",
        "h4": "迅速な利益",
        "h5": "1. 同日収入、手数料なし",
        "h6": "2. 20年の安定成長とゼロ損失",
        "h7": "3. 24時間プロの市場状況モニタリング",
        "h8": "成長と野心のために建てられた",
        "h9": "通貨交換、チーム管理、会計および",
        "h10": "その他 - すべて1か所で。",
        "h11": "通貨交換リスク管理",
        "h12": "マルチユーザー認証",
        "h13": "エコシステム統合",
        "h14": "World Firstについて",
        "h15": "歴史の年数",
        "h16": "顧客の信頼",
        "h17": "グローバルな足跡",
        "h18": "取引価値",
        "h19": "お客様の声を聞く",
        "h20": "通貨交換、チーム管理、会計および",
        "h21": "その他 - すべて1か所で。",
        "h22": "ピーター・キーン",
        "h23": "電気ディレクター",
        "h24": "WORLD FISCALを使用することで、私は安心感を得ます。なぜなら、私たちが関わるコミュニティの責任が尊重され、保護されていることを知っているからです。利益と資産を安全に保ちます。",
        "h25": "ラフィ・シエア",
        "h26": "マレーシアの銀行マネージャー",
        "h27": "私はマレーシアで銀行の商品を購入しましたが、非常に時間がかかり、難しかったですが、比較すると、WORLD FISCALを介して口座を開設することは簡単で効率的でした。",
        "h28": "モニカ・ミルカルズ",
        "h29": "Yingfaグループのディレクター",
        "h30": "Yu'e Baoは私が今まで買った中で最も安定した製品です。それは私が最も厳しい時期"
    },
    webLogin: {
        "w1": "ログイン",
        "w2": "登録",
        "w3": "アカウント",
        "w4": "パスワード",
        "w5": "国",
        "w6": "市",
        "w7": "アカウントを入力してください",
        "w8": "パスワードを入力してください",
        "w9": "ログインに成功しました",
        "w10": "登録に成功しました",
        "w11": '文字で始まる 長さ 6~18',
        "w12": '長さ 6~18',
        "w13": 'LINEでログイン',
        "w14": 'フェイスブックでログイン',
    },
    vip: {
        v1: '出力レート',
        v2: 'アップグレード金額',
        v3: 'アップグレード奨',
        v4: 'さらにご褒美はカスタマーサービスにお問い合わせください',
        v5: 'この奨励金は永遠に続くことはありません。今、私たちに参加して手厚い奨励金を得て、条項と条件は適用されます。私たちのウェブサイトを訪問して詳細を理解してください'
    },
    defi: {
        d1: '取引があれば利子がつく',
        d2: '製品の特徴',
        d3: '収益が安定している',
        d4: 'プラットフォームは通常、従来の銀行製品よりも高い金利を提供しています',
        d5: 'リスクの排除',
        d6: 'プラットフォームは「自己ホスティング」されており、ユーザー資産をセンター化されたオペレータにホスティングすることはありません',
        d7: '随時出金',
        d8: 'いつでもアクセスすることで、資金の柔軟性が向上し、通貨契約の安定化が容易になります',
        d9: '安定通貨プロトコル',
        d10: 'は安定通貨を使用して暗号化通貨の変動性を最小化する。これらの貨幣は通常、ドルなどの安定した貨幣と連結され、受け入れ可能な交換媒体と価値貯蔵になる',
    }


}