export default {
    common: {
        title: 'DeFi Wallet',
        symbol: '$',
        currency: 'dollars',
        login: 'login',
        lang1: 'Just now',
        lang2: ' minutes ago',
        lang3: ' hours ago',
        lang4: 'Yesterday',
        lang5: 'My Assets',
        lang6: 'Yesterday\'s Earnings',
        lang7: 'View More',
        lang8: "DeFi Savings",
        lang9: 'Fund',
        lang10: 'Optional',
        lang11: 'Daily income',
        lang12: '0 risk',
        lang13: 'low risk',
        lang14: 'medium risk',
        lang15: 'high risk',
        lang16: 'Increase and decrease in the past three years',
        lang17: 'Increase and decrease in the past year',
        lang18: 'Cumulative income',
        lang19: 'Buy this product',
        lang20: 'Cancel',
        lang21: 'No data yet',
        lang22:'confirm',
        lang23:'Cancel',
        lang24:'No more',
        lang25: 'Language',
        lang26:'No card bound',
        lang27:'Order details',
        lang28:'Scan the QR code and add me as a friend',
        lang29:'Received successfully',
        lang30:'Copy success',
        lang31: 'Note'


       
    },
    shareCard: {
        lang1: 'hint',
        lang2: 'confirm',
        lang3: 'Adding a postcard will remove all cards, are you sure you want to add them?',
        lang4: 'Adding pictures will remove the photo card, are you sure to add it?',
        lang5: 'Shine',
        lang6: 'Complete',
    },
    tabbar: {
        lang1: 'Today',
        lang2: 'Fund',
        lang3: 'My'
    },
    login: {
        lang1: 'Create a new account',
        lang2: 'Already have an account',
        lang3: 'Contact customer service',
        lang4: 'Unauthorized login, please re-authorize'
    },
    home: {
        lang1: 'Preferred for new users',
        lang2: 'Never lose money and withdraw money at any time',
        lang3: 'Buy a sum and try',
        lang4: 'Scarce asset sale reminder',
        lang5: 'Long press to follow the account',
        lang6: 'WeChat official',
        lang7: 'The number of investment users exceeds 400 million, and the assets under management exceed 2 trillion',
        lang8: 'Massive variety',
        lang9: 'Trade and purchase tens of thousands of bonds, funds, and futures around the world',
        lang10: 'security',
        lang11: 'Globally localized risk control team to strictly prevent account and capital risks',
        lang12: 'Income details'
    },
    balance: {
        lang1: 'Historical positive earnings every day, quick account withdrawal',
        lang2: 'View details',
        lang3: 'Total amount',
        lang4: 'Sell',
        lang5: 'buy',
        lang6: 'discussion board',
        lang7: 'The 4 major features of DeFi Savings',
        lang8: 'Stable income',
        lang9: 'Versatile',
        lang10: 'take out at any time',
        lang11: 'security',
        lang12: 'Transaction details',
        lang13: 'Income details',
        lang14: 'Transfer amount',
        lang15: 'Can be transferred out',
        lang16: 'Please enter the amount',
        lang17: 'Operation completed',
        lang18: 'Transfer amount',
        lang19: 'Have been informed of the risk warning and agreed to the service agreement',
        lang20: 'Please check the service agreement',
        lang21: 'can be transferred',
    },
    comment: {
        lang1: '"Yue Bao Discussion Forum"',
        lang2: 'Product details',
        lang3: 'latest',
        lang4: 'hot post',
        lang5: 'Posted',
        lang6: 'Express an opinion',
        lang7: 'Publish',
        lang8: 'Add your opinion',
        lang9: 'picture',
        lang10: 'expression',
        lang11: 'Content cannot be empty',
        lang12: 'Published successfully',
    },
    fund:{
        a1:'About us',
        a2:'Income',
        a3:'Total number of funds purchased',
        a4:"Yesterday's earnings",
        a5:'Total income',
        a6:'Principal protection',
        a7:'See more',
        a8: 'Balanced allocation of multiple industries, performance continues to outperform the market',
        a9:'Active selection',
        a10: 'Approved by over 310,000 users',
        a11:'Super market for eight consecutive years',
        a12:'AI-assisted quantification optimization',
        a13:'Multiple precise layouts',
        a14:'Focus on the style of small and medium caps',
        a15: 'Lay out high dividend-paying enterprises',
        a16:'The increase in five years exceeded 100%',
        a17:'Quantitative stock selection',
        a18:'Increase and decrease in the past three years',
        a19:'Increase and decrease in the past year',
        a20:'low risk',
        a21:'medium risk',
        a22:'medium-high risk',
        a23:'High risk',
        a24:'Cumulative profit for users',
        a25:'as of',
        a26:'Flexible redemption',
        a27:'Expert recommendation',
        a28:'Pursuing annual income',
        a29:'Robust level',
        a30:'Big data blessing',
        a31:'short cycle',
        a32:'Settled on the same day',
        a33:'Highly profitable',
        a34:'BUSINESSES LIKE YOURS TRUSTWORLD FISCAL',
        a35:'Since 2004, nearly 1,000,000 users worldwide have generated $8 billion in revenue through WORLD FISCAL and its partner brandsYour funds are protected by leading financial institutions'
        
        
    },
    fundPurchase:{
        b1:"Tencent Teng'an",
        b2:'Buy amount',
        b3:'Trading rules',
        b4:'The amount you can purchase funds is:',
        b5:'Buy rate',
        b6:'Please enter the payment password',
        b7:'Password is 6 digits',
        b8: 'I am aware of the risk warning and agree to the service agreement',
        b9:'Purchase successful',
        b10:'Incorrect input',
        b11:'Please check the box to agree',
        b12:'Choose to buy',
        b13:'Buy starting from 1 yuan',
        b14:"Expected profit:",
        b15:"Settlement method:",
        b16:"Day End",
        b17: "Settlement at maturity",
        b18:"Cycle duration:",
        b19:"Day",
        b20:"Detailed description:",
        b21:"No explanation yet",
       


    },
    user:{
        c1:'About us',
        c2:'About us',
        c3:'Welcome to the journey of wealth',
        c4:'My Assets',
        c5:'Trial money',
        c6:'Flexible Asset',
        c7:'Sound assets',
        c8:'High-level assets',
        c9:'Short-term return',
        c10:'Stable profit',
        c11:'Pursue profit',
        c12:'Tool service',
        c13:'Transaction Record',
        c14:'Balance record',
        c15:'Fund Record',
        c16:'More information',
        c17:'Account Security',
        c18:'My customer service',
        c19:'About us',
        c20:'Withdrawal',
        c21:'Recharge',
    },
  
    accountChanges:{
        d1:'About us',
        d2:'Account change record'
    },
    funChanges:{
        e1:'Fund transaction record',
        e2:'in progress',
        e3:'Expired',
        e4:'Remaining time',
        e5:'Day',
        e6:'Order details',
        e7:'Status:',
        e8:'in progress',
        e9:'Ended',
        e10:'Settlement suspended',
        e11:'Cycle duration',
        e12:'Product name',
        e13:'Order number',
        e14:'Purchase quantity',
        e15:'Cumulative income',
        e16:'Settlement method',
        e17:'Product Description',
        e18:'Start time',
        e19:'End time',
        e20:'Number of days remaining',
    },
    userInfo:{
        f1:'Personal information',
        f2:'Male',
        f3:'female',
        f4:"Age",
        f5:'Please select a country',
        f6:'Bank name',
        f7:'Bank card number',
        f8: "Please set a transaction password",
        f9:'Confirm transaction password',
        f10:'Please enter',
        f11:'Please enter again',
        f12:'Submit',
        f13:''
    },
    fundDetails:{
        e1:'About us',
        e2:'low risk',
        e3:'medium risk',
        e4:'medium to high risk',
        e5:'High risk',
        e6:'Minimum purchase of Yuan',
        e7:'Establish increase',
        e8:'Increase in the past year',
        e9:'Increase in the past three years',
        e10:'Buy now',
        e11:'Historical performance',
        e12: 'Transfer 10,000 yuan, and the daily income in the past 7 days is as follows',
        e13:'Purchase'
    },
    bankCard:{
        a1:'Add bank card',
        a2:'Select country',
        a3:'Select bank',a4:'Bank card number',
        a5:'Please enter your bank card number',
        a6:'Cardholder',
        a7: "Please enter the cardholder's name",
        a8:'View bound bank cards',
        a9:'bind',
        a10:'Please enter the payment password',
        a11:'Binding successful',
        a12:'Incorrect input',
        a13:'My card',
        a14:"You haven't bound the security card yet",
        a15:'Modify bank card',
        a16:'Security Card',
        a17:'Become a safe card',
        a18: 'The card used to purchase financial products for the first time will become a secure card',
        a19:'Remove security card',
        a20: 'The security card is bound to the financial account, and funds from the bank can only be withdrawn from the security card',
        a21:'Safe and guaranteed',
        a22: 'Even if your phone is lost, funds can only be withdrawn to your security card',
        a23:'Replace security card',
        a24: 'After passing the official review, the security card can be replaced',
        a25: 'Branch Name',
        a26: 'Branch Number',
        a27: 'kana',
        a28: 'Select Network',
        a29: 'Address',
        a30: 'Please Enter Address',

    },
    accountChanges:{
        a1:'account change',
        a2:'type',
        a3:'Select type',
        a4:'Order number',
        a5:'amount',
        a6:'Balance before purchase',
        a7:'Balance after purchase',
        a8:'time',
        a9:'Order number',
        a10:'Withdrawal',
        a11:'Freeze',
        a12:"Transfer to Yu'E Bao",
        a13:'Buy Fund',
        a14:'deduction',
        a15:'Daily login reward',
        a16:'Alipay recharge',
        a17:'Financial management expires',
        a18:"Transfer out of Yu'E Bao",
        a19:'Unfreeze',
        a20:'Withdrawal failed',
        a21:'Register for free',
        a22:'Activity',
        a23:'Daily financial management income',
        a24:'Cyclical product profits',
        a25:'gift',
        a26:'Holding Income'
    },
    setPwd:{
        a1:'Set transaction password',
        a2:'Transaction password',
        a3:'Please enter the 6-digit transaction password',
        a4:'Confirm transaction password',
        a5:'Please enter the transaction password again',
        a6:'Please enter the 6-digit transaction password',
        a7:'Save',
        a8:'Set successfully',
        a9: 'If there is an error in your input, please re-enter',
    },
    Withdraw:{
        a2:'Withdraw',
        a3:'Withdrawal amount',
        a4:'Transaction password',
        a5:'Enter transaction password',
        a6:'View withdrawal records',
        a7:'Withdraw',
        a8:'Withdrawing cash',
        a9:'Amount of cash that can be withdrawn'
    },
    WithdrawRecord:{
        e1:'Fund transaction record',
        e2:'in progress',
        e3:'Record',
        e4:'Withdrawal failed',
        e5:'Under review',
        e6:'Order details',
        e7:'Status:',
        e8:'Under review',
        e9:'Ended',
        e10:'Settlement suspended',
        e11:'Withdrawal address',
        e12:'Bank name',
        e13:'Cardholder',
        e14:'currency',
        e15:'Creation time',
        e16:'Withdrawal successful',
        e17:'Update time',
        e18:'Amount'
    },
    aboutas:{
        a1:"About Us",
        a2:"WorldFirst FOF was established in London, UK in 2004 and has since provided high-quality services to users worldwide. We rely on a cross-border financial ecosystem network, offering our customers rich product solutions and fund security guarantees.",
        a3: "Financial Licensing",
        a4:"We have qualifications for global expansion through ownership or cooperation with local brand agencies in many countries and regions including the USA, UK, Canada, Netherlands, Japan, Mainland China, Hong Kong, Malaysia, Singapore, and Australia, providing strong guarantees for customer fund safety and supporting customer global expansion!",
        a5: "Fund Security Compliance",
        a6: "Compliance Requirements",
        a7: "We have established a national standard compliance team, fully complying with international anti-money laundering and anti-terrorism financing standards, fully complying with relevant laws and regulations, and taking necessary steps to prevent financial risks.",
        a8: "Privacy Data Protection",
        a9: "Information and privacy are protected by strict privacy policies, managed meticulously, with minimal risk of misuse, with technical encryption for any data transfers, and restricted access with strict physical controls.",
        a10: "Transaction Fund Guarantee",
        a11: "All operations must be carried out according to customer instructions. Without authorization, third parties cannot manipulate customer funds.",
        a12: "Global EMC Investment",
        a13: "WorldFirst FOF has offices in 49 cities in 18 countries worldwide, providing more customized professional services.",
        a14: "Register now, open an account, and link global business opportunities."
    },
    recharge: {
        r1: 'Funds are under security',
        r2: 'Maximum compensation of one million dollars',
        r3: 'Real-time arrival',
        r4: 'No handling fee',
        r5: 'Withdraw money at any time',
        r6: 'Recharge amount',
        r7: 'Recharge now',
        r8: 'Why choose us',
        r9: 'diversified investment',
        r10: 'Higher return',
        r11: 'Massive tutors',
        r12: '0 loss',
        r13: 'Recharge',
        r14: 'Payment Method',
        r15: 'Enter Amount',
        r16: 'Please select',
        r17: 'Recharge Records',
        r18: 'Payment Method',
        r19: 'Please select a country',
        r20: 'Payment Link',
        r21: 'Please copy and open in browser',
        r22: 'Paid',
        r23: 'Copy',
        r24: 'Copy successful!',
        r25: 'Processing',
        r26: 'Top-up success',
        r27: 'Top-up failed'
    },
    invite: {
        i1: 'Claim reward',
        i2: 'Please enter invitation code',
        i3: 'Please enter correct invitation code'
    },
    home1: {
        h1: "MAKE MONEY LIKE A PRO",
        h2: "Quick Withdrawals",
        h3: "Professional operation",
        h4: "Quick profit",
        h5: "1. Same day income, 0 handling fee",
        h6: "2. 20 years of stable growth and zero losses",
        h7: "3. 24-hour professional monitoring of market conditions",
        h8: "Built for Growth and Ambition",
        h9: "Currency Exchange, Team Management, Accounting And",
        h10: "More – All In One Place.",
        h11: "Currency Exchange Risk Management",
        h12: "Multi-User Authorization",
        h13: "Ecosystem Integration",
        h14: "About Worldfirst",
        h15: "Years Of History",
        h16: "Customers's Trust",
        h17: "Global Footprint",
        h18: "Transaction Value",
        h19: "Hear What Our Customers Say",
        h20: "Currency Exchange, Team Management, Accounting And",
        h21: "More – All In One Place.",
        h22: "Peter Keen",
        h23: "Electrical Director",
        h24: "Using WORLD FISCAL gives me peace of mind，Because I know the responsibilities of the communities we work with are respected and safeguarded，Keeping my profits and assets safe",
        h25: "Raffi Schier",
        h26: "malaysian bank manager",
        h27: "I purchased banking products in Malaysia and found it very time consuming and challenging,but in comparison, opening an account through WORLD FISCAL was easy and efficient",
        h28: "Monica Milcarz",
        h29: "Director of Yingfa Group",
        h30: "Yu'e Bao is the most stable product I have ever purchased.It helped me get through the toughest times and makes me a better person now.",
    },
    webLogin: {
        "w1": "Login",
        "w2": "Register",
        "w3": "Account",
        "w4": "Password",
        "w5": "Country",
        "w6": "City",
        "w7": "Please enter account",
        "w8": "Please enter password",
        "w9": "Login successful",
        "w10": "Registration successful",
        "w11": 'Beginning with a letter, length 6~18',
        "w12": 'Length 6~18',
        "w13": 'Login with LINE',
        "w14": 'Login with facebook',
    },
    vip: {
        v1: 'Output',
        v2: 'Wallet',
        v3: 'award',
        v4: 'For more rewards, please contact customer service',
        v5: 'This incentive will not last forever. Join us now to receive generous rewards, but be sure to comply with terms and conditions. Please visit our website for more information.'
    },
    defi: {
        "d1": "There are dividends with transactions",
        "d2": "Products and Features",
        "d3": "Stable returns",
        "d4": "The platform usually offers higher interest rates than traditional bank products",
        "d5": "Eliminate risk",
        "d6": "The platform is [self-custody] and will never entrust user assets to a centralized operator",
        "d7": "Free access anytime",
        "d8": "Free access allows for more flexible and convenient funds",
        "d9": "Stablecoin protocol",
        "d10": "Use stablecoins to minimize the volatility of cryptocurrencies. These coins are often pegged to stable currencies such as the US dollar, making them an acceptable medium of exchange and store of value"
    }

}