export default {
    common: {
        title: 'DeFi Wallet',
        symbol: '¥',
        currency: 'USD',
        login: '登录',
        lang1: '最近',
        lang2: '分钟前',
        lang3: '小时前',
        lang4: '昨天',
        lang5: '我的资产',
        lang6: '昨日收益',
        lang7: '查看更多',
        lang8: 'DeFi 储蓄',
        lang9: '基金',
        lang10: '自选',
        lang11: '日化收益',
        lang12: '0风险',
        lang13: '低风险',
        lang14: '中风险',
        lang15: '高风险',
        lang16: '近3年涨跌幅',
        lang17: '近1年涨跌幅',
        lang18: '累计收益',
        lang19: '购买该产品',
        lang20: '取消',
        lang21: '暂时没有数据' ,
        lang22:'确认',
        lang23:'取消',
        lang24:'没有更多了',
        lang25: '语言',
        lang26:'没有绑定卡片',
        lang27:'帐单详情',
        lang28:'扫描二维码,加我为好友',
        lang29:'领取成功',
        lang30:'复制成功',
        lang31: '备注'
        
    },
    shareCard: {
        lang1: '提示',
        lang2: '确认',
        lang3: '添加晒一晒卡片会移除所有,确定添加吗',
        lang4: '添加图片会移除晒一晒卡片,确定添加吗',
        lang5: '晒一晒',
        lang6: '完成',
    },
    tabbar: {
        lang1: '今日',
        lang2: '基金',
        lang3: '我的'
    },
    login: {
        lang1: '创建新账号',
        lang2: '已有帐号',
        lang3: '联系客服',
        lang4: '未授权登录,请重新授权'
    },
    home: {
        lang1: '新用户优选',
        lang2: '永不亏钱  随时提款',
        lang3: '买一笔试试',
        lang4: '稀缺资产开售提醒',
        lang5: '长按关注账号',
        lang6: '微信官方',
        lang7: '投资用户超4亿，管理资产超20000亿',
        lang8: '海量品种',
        lang9: '交易和购买全球数万种 债券 基金 期货',
        lang10: '安全保障',
        lang11: '全球本地化风控团队，严防账户和资金风险',
        lang12: '收益详情'
    },
    balance: {
        lang1: '历史天天正收益，快速提取账户',
        lang2: '查看明细',
        lang3: '总金额',
        lang4: '卖',
        lang5: '买',
        lang6: '讨论区',
        lang7: 'DeFi 储蓄4大特性',
        lang8: '收益稳定',
        lang9: '用途广泛',
        lang10: '随时取出',
        lang11: '安全保障',
        lang12: '交易明细',
        lang13: '收益明细',
        lang14: '转出金额',
        lang15: '可以转出',
        lang16: '请输入金额',
        lang17: '操作完成',
        lang18: '转入金额',
        lang19: '已知悉风险提示并同意服务协议',
        lang20: '请勾选服务协议',
        lang21: '可以转入',
    },
    comment: {
        lang1: '"余额宝讨论区"',
        lang2: '产品详情',
        lang3: '最新',
        lang4: '热帖',
        lang5: '发表了帖子',
        lang6: '发表观点',
        lang7: '发表',
        lang8: '发表你的看法',
        lang9: '图片',
        lang10: '表情',
        lang11: '内容不能为空',
        lang12: '发表成功',
    },
    fund:{
        a1:'关于我们',
        a2:'收益',
        a3:'购买基金总数',
        a4:'昨日收益',
        a5:'总收益',
        a6:'本金保障',
        a7:'查看更多',
        a8:'均衡配置多行业，业绩连续跑赢大盘',
        a9:'主动优选',
        a10:'超31万用户认可',
        a11:'连续八年超大盘',
        a12:'AI辅助量化优选',
        a13:'多次精准布局',
        a14:'关注中小盘风格',
        a15:'布局高分红企业',
        a16:'5年涨幅超100%',
        a17:'量化选股',
        a18:'近3年涨跌幅',
        a19:'近1年涨跌幅',
        a20:'低风险',
        a21:'中风险',
        a22:'中高风险',
        a23:'高风险',
        a24:'累计为用户盈利',
        a25:'截至',
        a26:'灵活申赎',
        a27:'专家推荐',
        a28:'追求年收益',
        a29:'稳健水平',
        a30:'大数据加持',
        a31:'周期短',
        a32:'当日结',
        a33:'高盈利',
        a34:'像您的 Trustworld Fiscal 這樣的企業',
        a35:'自 2004 年以來，全球近 1,000,000 位用戶透過 WORLD FISCAL 及其合作品牌創造了 80 億美元的收入，您的資金受到領先金融機構的保護'
 },
    fundPurchase:{
        b1:'腾讯腾安',
        b2:'买入金额',
        b3:'交易规则',
        b4:'您可以购买基金的额度为:',
        b5:'买入费率',
        b6:'请输入支付密码',
        b7:'密码为 6 位数字',
        b8:'已悉知风险提示并且同意服务协议',
        b9:'购买成功',
        b10:'输入有误',
        b11:'请勾选同意',
        b12:'	选择购买',
        b13:'1元起购',
        b14:"预期利润：",
        b15:"结算方式:",
        b16:"日结",
        b17:"到期结算",
        b18:"周期时长:",
        b19:"天",
        b20:"详细说明:",
        b21:"暂无说明",
        


    },
    user:{
        c1:'关于我们',
        c2:'关于我们',
        c3:'欢迎开启财富之旅',
        c4:'我的资产',
        c5:'试用金',
        c6:'灵活资产',
        c7:'稳健资产',
        c8:'高阶资产',
        c9:'短期回报',
        c10:'稳定盈利',
        c11:'追求盈利',
        c12:'工具服务',
        c13:'账变记录',
        c14:'余额记录',
        c15:'基金记录',
        c16:'更多信息',
        c17:'账户安全',
        c18:'我的客服',
        c19:'关于我们',
        c20:'提款',
        c21:'充值',
    },
   
    accountChanges:{
        d1:'关于我们',
        d2:'账变记录'
    },
    funChanges:{
        e1:'基金交易记录',
        e2:'进行中',
        e3:'已过期',
        e4:'剩余时间',
        e5:'天',
        e6:'订单详情',
        e7:'状态：',
        e8:'进行中',
        e9:'已结束',
        e10:'暂停结算',
        e11:'周期时长',
        e12:'产品名称',
        e13:'订单号',
        e14:'购买数量', 
        e15:'累计收益',
        e16:'结算方式',
        e17:'产品说明',
        e18:'开始时间',
        e19:'结束时间',
        e20:' 剩余天数',

        
    },
    userInfo:{
        f1:'个人信息',
        f2:'男',
        f3:'女',
        f4:"年龄",
        f5:'请选择国家',
        f6:'银行名称',
        f7:'银行卡号',
        f8:"请设置交易密码",
        f9:'确认交易密码',
        f10:'请输入',
        f11:'请再次输入',
        f12:'提交',
        f13:''
    },
    fundDetails:{
        e1:'关于我们',
        e2:'低风险',
        e3:'中风险',
        e4:'中高风险',
        e5:'高风险',
        e6:'元起购',
        e7:'成立涨幅',
        e8:'近1年涨幅',
        e9:'近3年涨幅',
        e10:'立即买入',
        e11:'历史业绩',
        e12:'转入1万元,过去7天的每日收益如下',
        e13:'购买'
    },
    bankCard:{
        a1:'添加银行卡',
        a2:'选择国家',
        a3:'选择银行',
        a4:'银行卡号',
        a5:'请输入银行卡号',
        a6:'持卡人',
        a7:'请输入持卡人姓名',
        a8:'查看已绑定的银行卡',
        a9:'绑定',
        a10:'请输入支付密码',
        a11:'绑定成功',
        a12:'输入有误',
        a13:'我的卡片',
        a14:'你还没有绑定过安全卡',
        a15:'修改银行卡',
        a16:'安全卡',
        a17:'成为安全卡',
        a18:'首次购买理财产品的卡将成为安全卡',
        a19:'安全卡取出',
        a20:'安全卡与理财账户绑定，来自银行的资金可以仅能取出自安全卡',
        a21:'安全有保障',
        a22:'即使手机丢失,资金也仅能取出到你的安全卡',
        a23:'更换安全卡',
        a24:'通过官方审核后，可更换安全卡',
        a25:'分行名',
        a26:'分行号码',
        a27: '假名',
        a28: '选择网络',
        a29: '地址',
        a30: '请输入地址',

    },
    accountChanges:{
        a1:'账变',
        a2:'类型',
        a3:'选择类型',
        a4:'订单号',
        a5:'金额',
        a6:'购买前余额',
        a7:'购买后余额',
        a8:'时间',
        a9:'订单号',
        a10:'提现成功',
        a11:'冻结',
        a12:'余额宝转入',
        a13:'购买基金',
        a14:'扣除',
        a15:'每日登录奖励',
        a16:'支付充值',
        a17:'理财产品到期',
        a18:'余额宝转出',
        a19:'解冻',
        a20:'提现失败',
        a21:'注册赠送',
        a22:'活动',
        a23:'理财产品每日收益',
        a24:'周期产品盈利',
        a25:'赠送',
        a26:'持有收益'
    },
    setPwd:{
        a1:'设置交易密码',
        a2:'交易密码',
        a3:'请输入6位交易密码',
        a4:'确认交易密码',
        a5:'请再次输入交易密码',
        a6:'请输入6位数交易密码',
        a7:'保存',
        a8:'设置成功',
        a9:'输入有误请重新输入',
    },
    Withdraw:{
        a2:'提现',
        a3:'提现金额',
        a4:'交易密码',
        a5:'输入交易密码',
        a6:'查看提现记录',
        a7:'提现',
        a8:'正在提现',
        a9:'可提现金额'
    },
    WithdrawRecord:{
        e1:'基金交易记录',
        e2:'进行中',
        e3:'记录',
        e4:'提现失败',
        e5:'正在审核',
        e6:'订单详情',
        e7:'状态：',
        e8:'正在审核',
        e9:'已结束',
        e10:'暂停结算',
        e11:'提现地址',
        e12:'银行名',
        e13:'持卡人',
        e14:'币种',
        e15:'创建时间',
        e16:'提现成功',
        e17:'更新时间',
        e18:'金额'
    },
    aboutas:{
        a1: "关于我们",
        a2: "WorldFirst FOF成立于2004年，总部位于英国伦敦，自成立以来一直为全球用户提供高质量的服务。我们依赖跨境金融生态系统网络，为客户提供多样化的产品解决方案和资金安全保障。",
        a3: "金融许可证",
        a4: "我们具备通过拥有或与多个国家和地区的本地品牌机构合作进行全球扩展的资质，包括美国、英国、加拿大、荷兰、日本、中国大陆、香港、马来西亚、新加坡和澳大利亚，在客户资金安全和支持客户全球扩展方面提供强有力的保障！",
        a5: "资金安全合规性",
        a6: "合规要求",
        a7: "我们成立了国家标准合规团队，全面遵守国际反洗钱和反恐融资标准，完全遵守相关法律法规，并采取必要措施防范金融风险。",
        a8: "隐私数据保护",
        a9: "信息和隐私受严格的隐私政策保护，经过精心管理，最小化滥用风险，所有数据传输都进行技术加密，访问受到严格的物理控制限制。",
        a10: "资金交易保障",
        a11: "所有操作必须根据客户指令进行。未经授权，第三方无法操纵客户资金。",
        a12: "全球EMC投资",
        a13: "WorldFirst FOF在全球18个国家的49个城市设有办事处，提供更加个性化的专业服务。",
        a14: "立即注册，开设账户，抓住全球商机。"
    },
    recharge: {
        r1: '资金安全保障中',
        r2: '最高获百万资金赔付',
        r3: '实时到账',
        r4: '免手续费',
        r5: '随时提款',
        r6: '充值金额',
        r7: '立即充值',
        r8: '为什么选择我们',
        r9: '多元化投资',
        r10: '更高收益',
        r11: '海量导师',
        r12: '0亏损',
        r13: '充值',
        r14: '充值方式',
        r15: '请输入金额',
        r16: '请选择充值方式',
        r17: '充值记录',
        r18: '支付方式',
        r19: '请选择国家',
        r20: '支付链接',
        r21: '请复制到浏览器打开',
        r22: '已支付',
        r23: '复制',
        r24: '复制成功!',
        r25: '处理中',
        r26: '充值成功',
        r27: '充值失败',
    },
    invite: {
        i1: '领奖',
        i2: '请输入邀请码',
        i3: '请输入正确的邀请码'
    },
    home1: {
        "h1": "像专业人士一样赚钱",
        "h2": "快速取款",
        "h3": "专业运作",
        "h4": "快速盈利",
        "h5": "1. 同日收入，无手续费",
        "h6": "2. 20年稳健增长，零损失",
        "h7": "3. 24小时专业市场监控",
        "h8": "为成长和雄心而建",
        "h9": "货币兑换，团队管理，会计以及",
        "h10": "更多 - 一站式解决。",
        "h11": "货币兑换风险管理",
        "h12": "多用户授权",
        "h13": "生态系统整合",
        "h14": "关于World First",
        "h15": "历史年数",
        "h16": "客户的信任",
        "h17": "全球足迹",
        "h18": "交易价值",
        "h19": "听听我们的客户说什么",
        "h20": "货币兑换，团队管理，会计以及",
        "h21": "更多 - 一站式解决。",
        "h22": "Peter Keen",
        "h23": "电气总监",
        "h24": "使用WORLD FISCAL让我心安，因为我知道我们合作的社区的责任受到尊重和保护，保持我的利润和资产安全",
        "h25": "Raffi Schier",
        "h26": "马来西亚银行经理",
        "h27": "我在马来西亚购买银行产品，发现非常耗时和具有挑战性，但与此相比，通过WORLD FISCAL开设账户很容易和高效",
        "h28": "Monica Milcarz",
        "h29": "英发集团董事",
        "h30": "余额宝是我购买过的最稳定的产品。它帮助我度过了最艰难的时期，现在让我成为一个更好的人。"
    },
    webLogin: {
        w1: '登录',
        w2: '注册',
        w3: '账户',
        w4: '密码',
        w5: '国家',
        w6: '城市',
        w7: '请输入账户',
        w8: '请输入密码',
        w9: '登录成功',
        w10: '注册成功',
        w11: '字母开头长度在6~18之间',
        w12: '长度在6~18之间',
        "w13": 'Login with LINE',
        "w14": 'Login with facebook',
    },
    vip: {
        v1: 'Output',
        v2: 'Wallet',
        v3: 'award',
        v4: '更多奖励请联系客服',
        v5: '这种激励不会永远持续下去。立即加入我们，获得丰厚的奖励，但须遵守条款和条件。请访问我们的网站了解更多'
    },
    defi: {
        d1: '有交易就有分红',
        d2: '产品解读 特点描述',
        d3: '收益稳定',
        d4: '平台通常提供比传统银行产品更高的利率',
        d5: '消除风险',
        d6: '平台是[ 自我托管 ] 的，永远不会将用户资产托管给某个中心化的运营商',
        d7: '随时存取',
        d8: '随时存取可以让资金更加灵活，更加方便',
        d9: '稳定币协议',
        d10: '使用稳定币来最小化加密货币的波动性。这些币通常与美元等稳定货币挂钩，使其成为可接受的交换媒介和价值存储',
    }


}